<template>
	<nav class="breadcrumbs" v-if="fields.isChangeOrder === false && visited > 0" :class="{'active': visited >= 1}">
		<router-link to="/Date" :class="visited >= 1 ? 'active' : ''">Date</router-link> 

		<span :class="{'active': visited >= 3}">&gt;</span>
		<router-link to="/location" :class="visited >= 2 ? 'active' : ''">Location</router-link> 

		<span :class="{'active': visited >= 2}">&gt;</span>
		<router-link to="/scope-of-work" :class="visited >= 3 ? 'active' : ''">Scope Of Work</router-link> 

		<span :class="{'active': visited >= 4}">&gt;</span>
		<router-link to="/company" :class="visited >= 4 ? 'active' : ''">Company</router-link> 

		<span :class="{'active': visited >= 5}">&gt;</span>
		<router-link to="/contact" :class="visited >= 5 ? 'active' : ''">Contact</router-link>

		<span :class="{'active': visited >= 6}">&gt;</span>
		<router-link to="/review" :class="visited >= 6 ? 'active' : ''">Review Application</router-link>
	</nav>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'Breadcrumbs',
	computed: mapState(['visited', 'fields'])
}
</script>

<style lang="scss" scoped>

	.breadcrumbs{
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		font-size: .9rem;
		margin-bottom: 50px;
		visibility: hidden;
		&.active { visibility: visible; }
	}

	a{
		display: block;
		text-decoration: none;
		font-style: italic;
		color: #666;
		pointer-events: none;

		&.active {
			color: #fff;
			pointer-events: all;
			&.router-link-active{
				color: var(--orange);
				font-weight: bold;
			}

			&:hover{
				color: var(--yellow);
			}			
		}

	}
	
</style>