<template>
	<div class="radio-contain" :class="{disabled}">
		<p class="label">{{ label }}<span class="required" v-if="required"> *</span></p>
		<div class="radio-row">
			<label v-for="option of options">
				<input 
					type="radio" 
					:name="name" 
					class="radio-input" 
					:value="option.value || option" 
					@input="$emit('update:value', option.value || option)"
					:checked="value === (option.value || option)"
					:disabled="sameAsActive">
				<span>{{ option.label || option }}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Radio',
	props:{
		required:{
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			required: false
		},
		label: {
			type: String,
			default: ''
		},
		options: {
			type: Array,
			default: []
		},
		sameValue:{
			type: String,
			required: false
		},
		sameAsActiveExternal: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			sameAsActive: false
		}
	},
}
</script>

<style lang="scss" scoped>

	.radio-contain{
		margin: 2rem 0;

		&.disabled{
			opacity: .7;
		}
	}

	label{
		display: inline-block;
		cursor: pointer;
		margin-top: .5rem;
		
		span{
			padding: 10px 35px;
			display: inline-block;
			position: relative;

			&:after{
				content:'';
				border: solid 2px #fff;
				height: 16px;
				width: 16px;
				position: absolute;
				top: 10px;
				left: 10px;
				border-radius: 100px;
			}
		}

		input:checked + span:after{
			background: var(--yellow);
			border-color: var(--yellow);
		}
	}

	.radio-input{
		display: none;
	}
	
	.required{
		color: var(--red);
	}
</style>