<template>
	<button title="Toggle Feedback Window" id="toggle-feedback-window" :data-is-window-open="isWindowOpen" @click.prevent="toggleFeedback">
  <span class="text">Give Us Your Feedback</span>
  <span class="icon-contain">
    <span class="icon thought-icon">
      <svg width="30" height="30" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <path fill="none" d="m17.09 50.75c-3.184-4.073-5.088-9.191-5.088-14.75 0-13.23 10.77-24 24-24s24 10.77 24 24-10.77 24-24 24c-1.955 0-3.855-0.2406-5.676-0.6839-5.919-1.481-10.33-4.341-13.24-8.567z" />
          <circle cx="23.48" cy="54.97" r="5" fill="none" />
          <circle cx="-15.8" cy="62.94" r="2.5" transform="scale(-1,1)" fill="none" />
        </g>
        <g id="line">
          <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3" d="m31.54 58.56c1.442 0.2858 2.93 0.4391 4.455 0.4391 12.68 0 23-10.32 23-23s-10.32-23-23-23-23 10.32-23 23c0 4.913 1.552 9.467 4.187 13.21" />
          <circle cx="26.79" cy="36" r="2" />
          <circle cx="36" cy="36" r="2" />
          <circle cx="45.21" cy="36" r="2" />
          <circle cx="-4.026" cy="64.73" r="2.5" transform="matrix(-.9831 .1829 .1829 .9831 0 0)" fill="none" stroke="currentcolor" stroke-miterlimit="10" stroke-width="3" />
          <circle cx="-13.58" cy="57.88" r="4.981" transform="matrix(-.9831 .1829 .1829 .9831 0 0)" fill="none" stroke="currentcolor" stroke-miterlimit="10" stroke-width="3" />
        </g>
      </svg>
    </span>
    <span class="icon close-icon">
      <svg width="30" height="30" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="line">
          <line x1="17.5" x2="54.5" y1="17.5" y2="54.5" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
          <line x1="54.5" x2="17.5" y1="17.5" y2="54.5" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
        </g>
      </svg>
    </span>
  </span>
</button>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
	computed: {
		...mapState(['isWindowOpen']),
		...mapMutations(['toggleFeedback'])
	}
}
</script>




<style lang="scss" scoped>
  #toggle-feedback-window {
    position: relative;
    background: var(--orange);
    border: solid 3px #000;
    padding: 0;
    padding-left: 10px;
    height: 45px;
    width: 260px;
    border-radius: 10px;
    color: var(--grey);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 300ms;

    & > * {
      display: block;
    }

    .icon-contain {
      width: 40px;
      height: 40px;
      position: relative;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 250ms;

      &.thought-icon {
        top: 0;
        left: 0;
      }
      &.close-icon {
        top: 100%;
        left: 0;
      }
    }

    &[data-is-window-open="true"] {
      .icon {
        transform: translateY(-100%);
      }
    }

    &:hover {
      background: var(--yellow);
    }
  }
</style>