<template>

	<div class="floater-wrap">
		<div class="wrap">
			<button class="btn floater" @click="showFAQ = !showFAQ">?</button>
		</div>
	</div>
	
	<div class="faqs" v-if="showFAQ">
		<div class="wrap">
			<button class="close" @click="showFAQ = false">&times;</button>
			<h3>To place an order, you will need to provide the following information:</h3>
			<ul>
				<li>Your name, phone number, and the company that should be billed or the owner of the project.</li>
				<li>The foreman name and number</li>
				<li>The date, address, report time, special requests</li>
				<li>Is there invoicing information required?</li>
				<li>
					Please provide any of the following…
					<ul>
						<li>PO Number, Job Numbers, Job ID / Name, Prevailing wage Rates (if applicable)</li>
					</ul>
				</li>
			</ul>

			<button class="btn" @click="showFAQ = false">Hide FAQ</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showFAQ: false
		}
	},
}
</script>

<style lang="scss" scoped>

ul{
  li{
    margin: 15px 0;

    &::marker{
      color: var(--orange);
    }
  }
}

h2{
	color: var(--orange);
	margin-top: 1rem;
}
h3{
	color: var(--yellow);
}

.floater-wrap {
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: 100%;
	height: 1px;
  .wrap {
		position: relative;
		height: 1px;
	}
}

button.btn.floater{
	position: absolute;
	bottom: 15px;
	right: 0px;
	width: 50px;
	height: 50px;
	padding: 0;
	display: flex;
	z-index: 998;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	border-radius: 100%;
	border: solid 2px #fff;
}

.faqs {
		background: rgba(0,0,0,0.85);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.wrap {
			background: #000;
			max-width: 800px;
			padding: 10px;
			border: solid 1px var(--orange);
			color: var(--orange);
			position: relative;
			.close {
				position: absolute;
				top: 10px;
				right: 10px;
				display: flex;
				width: 32px;
				height: 32px;
				font-size: 32px;
				line-height: 32px;
				background: #000;
				color: var(--orange);
				border: solid 1px var(--orange);
				border-radius: 50%;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}
}

</style>