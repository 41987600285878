<template>
	

	<p v-if="!$store.state.isRequestingQuote">
		Great! Please tell us about your traffic control needs and complete our order request form.
	</p>
	<p v-else>
		Please tell us about your upcoming traffic control needs and provide key information that our business development team will use to establish your account.
	</p>

	<h2>Date</h2>
	<h3 class="text-with-icon">
			<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 7L12 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M12 17.01L12.01 16.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
			</svg> 
			Only select the dates you plan to work.
	</h3>

	<p class="yellow">Note: Dates displayed in yellow reflect weekends and holidays.</p>

	<FatPickr 
		v-model:value="fields.date.datesOfWork"
		label="Date(s) Of Work"
		name="datesOfWork"
		:alwaysVisible="true"
		:required="true" 
		:holidayArr="[ '2021-12-24', '2021-12-25', '2021-12-31', '2022-01-17', '2022-02-21', '2022-05-30', '2022-06-20', '2022-07-04', '2022-09-05', '2022-10-10', '2022-11-11', '2022-11-24', '2022-12-26' ]"	/>

	<TimePicker 
		v-if="!$store.state.isRequestingQuote"
		name="startTime"
		label="Start Time"
		:required="true"
		v-model:value="fields.date.startTime" />

	<TimePicker
		v-if="!$store.state.isRequestingQuote" 
		name="endTime"
		label="End Time"
		:required="true"
		v-model:value="fields.date.endTime" />

		<Radio v-if="$store.state.isRequestingQuote"  label="Time Slot" v-model:value="fields.date.timeSlot" name="timeSlot" :options="['Daytime (5am - 3pm)', 'Nighttime (3pm - 5am)', 'Both']" />

	<TextArea 
		name="dateNotes"
		label="Date/Time Notes"
		placeholder="Different start/end times on certain days, etc"
		v-model:value="fields.date.dateNotes" />

	<ContinueBtn to="/location" :isVisible="showBtn" backTo="/" />

</template>

<script>
import TimePicker from '@/components/TimePicker.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'
import FatPickr from '@/components/FatPickr.vue'
import TextArea from '@/components/TextArea.vue'
import { mapState } from 'vuex'
import Radio from '../components/Radio.vue'

export default {
	components: {
    FatPickr,
    TimePicker,
    ContinueBtn,
    TextArea,
    Radio
},
	props: {
		showContinue: Boolean
	},
	data() {
		return {
			fieldsToCheck: ['date.datesOfWork', 'date.startTime', 'date.endTime']
		}
	},
	computed: {
		...mapState(['fields']),
		showBtn(){
			let showBtn = true

			if(this.fields.date.datesOfWork === '') showBtn = false
			if(/--/i.test(this.fields.date.startTime)) showBtn = false
			if(/--/i.test(this.fields.date.endTime)) showBtn = false

			return showBtn
		}
	},
	mounted(){
		this.$store.commit('addRoute', 1)
	}
}
</script>