<template>
	<div v-if="isWindowOpen" class="overlay" @click="isWindowOpen = false" />
		<div v-if="isWindowOpen" id="feedbackWindow">
			<FeedbackLoading :isLoading="isLoading" />
			<AfterSubmit :isSubmitted="isSubmitted" />
			<button @click="$store.commit('toggleFeedback')" class="close-btn" type="button" label="close feedback window">
				<svg width="30" height="30" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
					<g id="line">
						<line x1="17.5" x2="54.5" y1="17.5" y2="54.5" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
						<line x1="54.5" x2="17.5" y1="17.5" y2="54.5" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
					</g>
				</svg>
			</button>
			<header>
				<h4>How Do You Like This Order Form?</h4>
			</header>
			<form @submit.prevent="handleSubmit">
				<Rating />
				<Input v-model="companyName" label="Company Name (Required)" />
				<MoreDetails />

				<YesNo name="contact_me" label="Do You Want Us To Contact You?" v-model:value="contact_me" class="less-margin" />
				<Input v-if="contact_me === 'yes'" v-model="name" label="Your Name" />
				<Input v-if="contact_me === 'yes'" v-model="phone" label="Your Phone Number" />
				<Input v-if="contact_me === 'yes'" v-model="email" label="Your Email" />
		
				<button class="btn" v-if="rating !== null && companyName !== '' && ( (name !== '' && contact_me === 'yes' && phone !== '' )|| (contact_me === 'no'))">Submit Feedback</button>
		
	
					<div class="error" v-if="isError">
						<h5>Error</h5>
						<p>{{errorMsg}}</p>
					</div>

			</form>
		</div>
</template>

<script>
	import { mapState } from 'vuex'
  import Rating from "./Rating.vue";
  import MoreDetails from "./MoreDetails.vue";
	import Input from './Input.vue'
  import AfterSubmit from "./AfterSubmit.vue";
  import FeedbackLoading from "./FeedbackLoading.vue";
  import YesNo from "../YesNo.vue";

	export default {
		name: 'FeedbackWindow',
		components: {
			FeedbackLoading,
			Rating,
			MoreDetails,
			Input,
			AfterSubmit,
			YesNo
		},
		data() {
			return {
				isError: false,
				errorMsg: '',
				companyName: '',
				name: '',
				phone: '',
				email: '',
				isLoading: false,
				isSubmitted: false,
				contact_me: false
			}
		},
		computed: mapState(['isWindowOpen', 'rating', 'moreDetails']),
		methods: {
			async handleSubmit() {
				this.isLoading = true;
				const payload = {
					rating: this.rating,
					moreDetails: this.moreDetails,
					company: this.companyName,
					name: this.name,
					phone: this.phone,
					email: this.email,
					wants_to_be_contacted: this.contact_me
				}

				try {
					await fetch("https://flaggerforce.com/orders/orders_feedback.php", {
						method: "POST",
						body: JSON.stringify(payload),
					});
					this.isSubmitted = true;
				} catch (error) {
					this.isError = true;
					this.errorMsg = error;
				} finally {
					this.isLoading = false;
				}
			}
		}
  }
</script>

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 19;
    background: hsl(420 69% 0%);
    mix-blend-mode: saturation;

    @supports not (mix-blend-mode: saturation) {
      background: hsl(420 69% 0% / 50%);
    }
  }

  .close-btn {
    position: absolute;
    right: -2px;
    top: -2px;
    padding: 0.5rem;
    border: solid 2px currentColor;
    color: var(--yellow);
    width: 50px;
    height: 50px;
    background: transparent;
    transition: all 0.3s;

    &:hover {
      color: var(--orange);
    }
  }
  #feedbackWindow {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
    max-width: 375px;
    background: var(--grey);
    padding: 3rem 1rem;
    z-index: 20;
    isolation: isolate;

    border: solid 2px var(--yellow);

    @media screen and (max-height: 500px) {
      max-height: 90vh;
      overflow-y: auto;
    }
  }

  .error {
    margin-top: 1rem;
    width: 100%;
    background: var(--red);
    color: var(--red-dark);
    padding: 10px;
    isolation: isolate;

    h5 {
      font-size: 1.3rem;
      text-align: center;
      margin-bottom: 10px;
    }
  }
</style>