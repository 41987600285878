<template>
	<h2>Company</h2>

	<Input 
		label="Company Name"
		v-model:value="fields.company.companyName"
		name="companyName"
		:required="true"/>

	<YesNo
		label="Are you performing your work as a subcontractor?"
		name="areYouPerformingYourWorkAsASubcontractor"
		v-model:value="fields.company.areYouPerformingWorkAsSubcontractor" />

	<Input 
		v-if="fields.company.areYouPerformingWorkAsSubcontractor === 'yes'"
		v-model:value="fields.company.whatCompanyAreYouPerformingWorkFor"
		label="For what company are you performing as a subcontractor?"
		name="whatCompanyAreYouPerformingWorkFor"
		:required="true" />

	<Radio
		v-if="fields.company.companyName.length
					&& ( fields.company.areYouPerformingWorkAsSubcontractor === 'no' 
					|| (fields.company.areYouPerformingWorkAsSubcontractor === 'yes' && fields.company.whatCompanyAreYouPerformingWorkFor.length))"
		label="Name of the company being billed for traffic control work"
		name="nameOfTheCompanyBeingBilledForTrafficControlWork"
		v-model:value="fields.company.nameOfTheCompanyBeingBilledForTrafficControlWork"
		:options="companyBilledOptions" />

	<Input 
		v-if="fields.company.nameOfTheCompanyBeingBilledForTrafficControlWork === 'Other'"
		label="Name Of Company Being Billed"
		v-model:value="fields.company.companyBeingBilled"
		name="companyBeingBilled"
		:required="true" />

	<h3>Billing Details</h3>

	<YesNo 
		label="Are there specific billing details for this job such as job code, project ID, purchase order, etc?"
		name="areThereDetails"
		v-model:value="fields.company.areThereDetails" />

	<div v-if="fields.company.areThereDetails === 'yes'">
      <Input
        label="Provide all specific billing details for this job"
        name="job-project-identifiers"
        :required="true"
        placeholder="Job code, Project ID, Purchase Order, Work Order #s, etc."
        v-model:value="fields.company.jobProjectIdentifiers" />
  </div>
  <div v-else>
  	<p class="yellow"><em>Project identifiers are a critical part of our process. Missing billing details will impact our ability to provide service. Please be certain that there are no additional details you can provide.</em></p>
  </div>

	<ContinueBtn to="/contact" :isVisible="showBtn" />
</template>

<script>
import { mapState } from 'vuex'
import Input from '@/components/Input.vue'
import Radio from '@/components/Radio.vue'
import YesNo from '@/components/YesNo.vue'
import SameAsBtn from '@/components/SameAsBtn.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'
	
export default {
	name: 'Location',
	components:{
		Input,
		SameAsBtn,
		ContinueBtn,
		Radio,
		YesNo,
	},
	computed: {
		companyBilledOptions(){
			return this.fields.company.areYouPerformingWorkAsSubcontractor === 'yes' 
							? [this.fields.company.companyName, this.fields.company.whatCompanyAreYouPerformingWorkFor, 'Other'] 
							: [this.fields.company.companyName, 'Other']
		},
		...mapState(['fields']),
		showBtn(){
			let showBtn = true

			if(this.fields.company.companyName === '') showBtn = false
			if(this.fields.company.whatCompanyAreYouPerformingWorkFor === '' && this.fields.company.areYouPerformingWorkAsSubcontractor === 'yes') showBtn = false
			if(this.fields.company.nameOfTheCompanyBeingBilledForTrafficControlWork === '' ) showBtn = false
			if(this.fields.company.companyBeingBilled === '' && this.fields.company.nameOfTheCompanyBeingBilledForTrafficControlWork === 'Other') showBtn = false
			if(this.fields.company.areThereDetails === 'yes' &&
				this.fields.company.jobProjectIdentifiers === '') showBtn = false

			return showBtn
		}
	},
	mounted(){
		this.$store.commit('addRoute', 4)
	}
}
</script>

<style>
	.yellow { color: var(--yellow); }
</style>