<template>
	<div class="checkbox-contain">
		<p class="label">{{label}}<span class="required" v-if="required"> *</span></p>
		<label v-for="(option, index) in options" :key="option">
			<input type="checkbox" 
				:name="name" 
				:id="`${name}-${index}`" 
				:checked="value.includes(option)" 
				:value="option" 
				@input="handleInput(option)"
				:required="required">
			<span>{{ option }}</span>
		</label>
		<label v-if="includeNoneOfTheAbove">
			<input type="checkbox" 
				:name="name" 
				:id="`${name}-none-of-the-above`" 
				:checked="value.includes('None Of The Above')" 
				value="None Of The Above" 
				@input="handleInput('None Of The Above', true)"
				:required="required">
			<span>None Of The Above</span>
		</label>
	</div>
</template>

<script>
export default {
	name: 'Checkbox',
	props:{
		name: String,
		label: String,
		options: Array,
		value: {
			type: Array,
			default: []
		},
		required: {
			type: Boolean,
			default: false
		},
		includeNoneOfTheAbove:{
			type: Boolean,
			default: false
		}
	},
	methods: {
		handleInput(option, clearOthers = false){
			//Make a new array called currentArr using the current value state
			const currentArr = [...this.value]

			//this will be my new array
			let newArr;

			//check if the option that was clicked is already selected
			if(currentArr.includes(option)){
				//if already selected then filter it out of the array and return newArr
				newArr = currentArr.filter(opt => opt !== option)
			}else if(clearOthers){
				//If others need to be cleared out like if none of the above is picked
				newArr = [option]
			}else{
				//If it needs added then this will make a new array by spreading in the currentArr and just adding the new option at the end
				newArr = [...currentArr, option]
				newArr = newArr.filter(item => item !== 'None Of The Above')
			}

			//emit the value change to the parent with the new array
			this.$emit('update:value', newArr)
		}
	},
}
</script>

<style lang="scss" scoped>

.checkbox-contain{
	margin: 2rem 0;
}

label{
	display: block;
	padding: 1rem 0;
	text-transform: uppercase;
	font-weight: bold;
	font-family: var(--font-condensed);
	cursor: pointer;
	transition: color 300ms;

	&:hover{
		color: var(--yellow);
	}

	input{
		display: none;
	}

	span{
		padding-left: 35px;
		position: relative;
		display: block;
		min-height: 22px;
		line-height: 22px;

		&:after{
			content:'';
			display: block;
			border: solid 1px #fff;
			width: 20px;
			height: 20px;
			position: absolute;
			left: 1px;
			top: 0px;
		}

		&:before{
			content:'';
			display: block;
			background: var(--yellow);
			width: 20px;
			height: 20px;
			position: absolute;
			top: 0px;
			left: 0px;
			transform: scale(0);
			transition: transform 200ms;
		}

	}

	input:checked + span:before{
		transform: scale(1);
	}

}

</style>