<template>
  <div class="header-block">
    <h2>Request Cancellation</h2>
    <h3 class="text-with-icon">
      <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7L12 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 17.01L12.01 16.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> 
      You MUST have your correct Flagger Force order number to request a modification to an existing order.
    </h3>
    <p>By requesting to cancel this order, you are cancelling all dates associated with this order.</p>   
  </div>  


    <Input 
      label="Flagger Force Order Number"
      name="orderNumber"
      placeholder="6-digit order number"
      v-model:value="fields.changeOrder.orderNumber"
      :required="true" />

    <Textarea 
      v-model:value="fields.changeOrder.changeNotes"
      label="Reason for cancellation/notes"
      name="changeNotes"
      :required="true" />

    <h3>Person Requesting Change</h3>

    <Input 
      label="First Name"
      name="changeFirstName"
      v-model:value="fields.changeOrder.changeFirstName"
      :required="true" />

    <Input 
      label="Last Name"
      name="changeLastName"
      v-model:value="fields.changeOrder.changeLastName"
      :required="true" />

    <Input 
      label="phone"
      name="changePhone"
      type="phone"
      v-model:value="fields.changeOrder.changePhone"
      :required="true" />

    <Input 
      label="email"
      name="changeEmail"
      type="email"
      v-model:value="fields.changeOrder.changeEmail"
      :required="true" />

    <Input 
      label="company name"
      name="changeCompany"
      v-model:value="fields.changeOrder.changeCompany"
      :required="true" />

    <ContinueBtn to="/review" :isVisible="showBtn" />


</template>

<script>
import Input from '@/components/Input.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'
import Textarea from '@/components/TextArea.vue'
import { mapState } from 'vuex'

export default {
  name: 'CancelOrder',
  components: {
    Input,
    ContinueBtn,
    Textarea
  },
  computed:{
    ...mapState(['fields']),
    showBtn(){
      let showBtn = true

      if(this.fields.changeOrder.changeFirstName === '') showBtn = false
      if(this.fields.changeOrder.changeLastName === '') showBtn = false
      if(this.fields.changeOrder.changePhone === '') showBtn = false
      if(this.fields.changeOrder.changeEmail === '') showBtn = false
      if(this.fields.changeOrder.orderNumber === '') showBtn = false
      if(this.fields.changeOrder.changeCompany === '') showBtn = false

      return showBtn
    }
  },
  mounted() {
    this.fields.isChangeOrder = 'cancel'
  }
}
</script>