<template>
	<section>
    <h5>Please Share Any Comments About Your Experience (Optional)</h5>
    <textarea title="additional comments" placeholder="additional comments (optional)..." v-model="$store.state.moreDetails" />
  </section>
</template>

<style lang="scss" scoped>
  h5 {
    margin: 1rem 0 5px;
    color: var(--orange);
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 5px;
    font-family: var(--font);
    border: 2px solid transparent;

    &:focus {
      outline: solid 2px var(--orange);
    }
  }
</style>