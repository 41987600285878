<template>
  <div class="header-block">
    <h2>Request an Order Change</h2>
    <h3 class="text-with-icon">
      <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7L12 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 17.01L12.01 16.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> 
      You MUST have your correct Flagger Force order number to request a modification to an existing order.
    </h3>    
  </div>  

  
    <Input 
      label="Flagger Force Order Number"
      name="orderNumber"
      placeholder="6-digit order number"
      v-model:value="fields.changeOrder.orderNumber"
      :required="true" />

    <h3>Person Requesting Change</h3>

    <Input 
      label="First Name"
      name="changeFirstName"
      v-model:value="fields.changeOrder.changeFirstName"
      :required="true" />

    <Input 
      label="Last Name"
      name="changeLastName"
      v-model:value="fields.changeOrder.changeLastName"
      :required="true" />

    <Input 
      label="phone"
      name="changePhone"
      type="phone"
      v-model:value="fields.changeOrder.changePhone"
      :required="true" />

    <Input 
      label="email"
      name="changeEmail"
      type="email"
      v-model:value="fields.changeOrder.changeEmail"
      :required="true" />

    <Input 
      label="company name"
      name="changeCompany"
      v-model:value="fields.changeOrder.changeCompany"
      :required="true" />

    <Checkbox 
      label="What do you want to change?"
      name="whatChange"
      v-model:value="fields.changeOrder.whatChange"
      :required="true"
      :options="['Date/time', 'Location']" />

    <div v-if="fields.changeOrder.whatChange.includes('Date/time')">

      <p class="yellow">You may only request, via this form, to modify work dates for the next 14 days . For modifications outside this date range, please call 888.312.3524</p>

      <FatPickr 
        v-model:value="fields.changeOrder.date.datesOfWork"
        label="Date(s) Of Work"
        name="datesOfWork"
        :alwaysVisible="true"
        :required="true" 
        :range="14"
        :disabledArr="[ '2021-12-24', '2021-12-25', '2021-12-31', '2022-01-17', '2022-02-21', '2022-05-30', '2022-06-20', '2022-07-04', '2022-09-05', '2022-10-10', '2022-11-11', '2022-11-24', '2022-12-26' ]" />

      <TimePicker 
        name="startTime"
        label="Start Time"
        :required="true"
        v-model:value="fields.changeOrder.date.startTime" />

      <TimePicker 
        name="endTime"
        label="End Time"
        :required="true"
        v-model:value="fields.changeOrder.date.endTime" />

      <TextArea 
        name="dateNotes"
        label="Date/Time Notes"
        placeholder="Different start/end times on certain days, etc"
        v-model:value="fields.changeOrder.date.dateNotes" />

    </div>

    <div v-if="fields.changeOrder.whatChange.includes('Location')">


    <h3>Where is the traffic control work taking place?</h3>
    <Input
      label="Address"
      name="address-intersection-address"
      placeholder=""
      :required="true"
      v-model:value="fields.changeOrder.location.addressIntersection.address" />
    <Input
      label="City"
      name="address-intersection-city"
      placeholder=""
      :required="true"
      v-model:value="fields.changeOrder.location.addressIntersection.city" />
    
    <Input
      label="State"
      name="address-intersection-state"
      :required="true"
      placeholder=""
      v-model:value="fields.changeOrder.location.addressIntersection.state" />
    <Input
      label="Zip"
      name="address-intersection-Zip"
      :required="true"
      placeholder=""
      v-model:value="fields.changeOrder.location.addressIntersection.zip" />

    <TextArea 
      label="Additional Details"
      placeholder="Intersecting street names or landmarks"
      v-model:value="fields.changeOrder.location.addressIntersection.additionalDetails" />

    <YesNo
      label="Is Our Crew Meeting You At This Address?"
      v-model:value="fields.changeOrder.location.sameAsLocationOfWorkBool"
      :required="true" />

      <div class="where-should-we-meet-section" v-if="fields.changeOrder.location.sameAsLocationOfWorkBool === 'no'">

        <h3>Where Should We Meet You?</h3>

        <Input
          label="Address"
          name="address-intersection-address"
          placeholder=""
          :required="true"
          v-model:value="fields.changeOrder.location.preJobMeetingLocation.address"/>

        <Input
          label="City"
          name="address-intersection-city"
          placeholder=""
          :required="true"
          v-model:value="fields.changeOrder.location.preJobMeetingLocation.city" />
        
        
        <Input
          label="State"
          name="address-intersection-state"
          :required="true"
          placeholder=""
          v-model:value="fields.changeOrder.location.preJobMeetingLocation.state"/>
        <Input
          label="Zip"
          name="address-intersection-Zip"
          :required="true"
          placeholder=""
          v-model:value="fields.changeOrder.location.preJobMeetingLocation.zip" />

        <TextArea 
          label="Additional Details"
          placeholder="Rest area, service center, parking lot, gas station, etc"
          v-model:value="fields.changeOrder.location.preJobMeetingLocation.additionalDetails" />

      </div>

    </div>


    <ContinueBtn to="/review" :isVisible="showBtn" />

</template>

<script>
import Input from '@/components/Input.vue'
import FatPickr from '@/components/FatPickr.vue'
import TimePicker from '@/components/TimePicker.vue'
import TextArea from '@/components/TextArea.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'
import Checkbox from '@/components/Checkbox.vue'
import YesNo from '@/components/YesNo.vue'
import { mapState } from 'vuex'

export default {
  name: 'Modify',
  components: {
    Input,
    Checkbox,
    ContinueBtn,
    TextArea,
    FatPickr,
    TimePicker,
    YesNo
  },
  computed:{
    ...mapState(['fields']),
    showBtn(){
      let showBtn = true

      if(this.fields.changeOrder.changeFirstName === '') showBtn = false
      if(this.fields.changeOrder.changeLastName === '') showBtn = false
      if(this.fields.changeOrder.changePhone === '') showBtn = false
      if(this.fields.changeOrder.changeEmail === '') showBtn = false
      if(this.fields.changeOrder.orderNumber === '') showBtn = false
      if(this.fields.changeOrder.changeCompany === '') showBtn = false
      if(this.fields.changeOrder.whatChange.length === 0) showBtn = false

      return showBtn
    }
  },
  mounted() {
    this.fields.isChangeOrder = 'modify'
  }
}
</script>