<template>
	<div class="text-area-contain" :class="class">
		<label :for="name">
			<span class="label">{{ label }} <span class="required" v-if="required">*</span></span>

			<textarea rows="10" cols="50"
				:placeholder="placeholder"
				:value="value"
				:name="name"
				@input="$emit('update:value', $event.target.value)"
				:required="required">
			</textarea>
		</label>
	</div>
</template>

<script>
export default {
	name: 'TextArea',
	props: {
		value: {
			type: String,
			default: ''
		},
		name:{
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			required: false
		},
		label: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default:false
		},
		class: {
			required: false,
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>

	.text-area-contain{
		margin: 2rem 0;
		width: 100%;

		&.no-margin{
			margin: 0;
		}
	}

	label{
		width: 100%;
		display: block;
	}

	textarea{
		width: 100%;
		height: 125px;
		display: block;
		padding: 1rem;
		border: solid 2px #fff;
		transition: all 300ms;

		&:focus{
			border-color: var(--orange);
			outline: solid 1px var(--orange);
			outline-offset: 3px;
		}
	}
	
</style>