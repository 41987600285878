<template>
	<main class="feedback-survey-contain">
		<FeedbackWindow />
		<ToggleBtn />
	</main>
</template>

<script>
	import "./feedback-styles.scss";
	import ToggleBtn from './ToggleBtn.vue'
	import FeedbackWindow from './FeedbackWindow.vue'
	import { mapState } from 'vuex'
	export default{
		name: 'Feedback',
		components:{
			ToggleBtn,
			FeedbackWindow
		},
		computed: {
			...mapState(['isWindowOpen', 'rating', 'isSubmitted', 'moreDetails']),
		},
		methods: {
			resetStuff(){
					if (this.isWindowOpen === false && this.isSubmitted === true) {
					setTimeout(() => {
						this.rating = null;
						this.moreDetails = null;
						this.isSubmitted = false;
					}, 500);
				}
			}
		},
	}
</script>

<style>
	.feedback-survey-contain { margin-top: 5px; }
</style>

