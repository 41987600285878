<template>
	<div class="fatpickr">
		<p class="label">{{ label }}<span class="required" v-if="required"> *</span></p>
		<input type="text" name="dates" :id="name" :name="name" :value="valueFormatted" readonly @click="showCalendar = !showCalendar" :required="required" v-if="!alwaysVisible">

		<div class="overlay" v-if="showCalendar" @click="showCalendar = false"></div>

		<transition name="toggle-calendar">
			<section class="calendar" v-if="showCalendar || alwaysVisible" :class="alwaysVisible ? 'alwaysVisible' : ''">
				<div class="month-row">
					<button class="month-btn" @click="handlePrevMonth" :class="{'active' : prevMonthActive}">
						<svg width="24" height="24" stroke-width="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15 6L9 12L15 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</button>
					<div class="selected-month">
						<h3>{{ selectedMonthText }}</h3>
						<h4>{{ selectedYear }}</h4>
					</div>
					<button class="month-btn" @click="handleNextMonth" :class="{'active' : nextMonthActive}">
						<svg width="24" height="24" stroke-width="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6L15 12L9 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</button>
				</div>
				<div class="days-grid">
					<p class="weekday">Sun</p>
					<p class="weekday">Mon</p>
					<p class="weekday">Tues</p>
					<p class="weekday">Wed</p>
					<p class="weekday">Thurs</p>
					<p class="weekday">Fri</p>
					<p class="weekday">Sat</p>
					<div class="spacer" v-if="leadingSpan > 0" :style="`--spacer: ${leadingSpan}`"></div>
					
					<button class="day"
						v-for="day of days"
						@click="handleDayClick(day)"
						:class="{ 'picked': isPicked(day), 'weekend': isWeekend(day), 'holiday': isHoliday(day), 'today': isToday(day), 'inactive': isNotInRange(day) }"
					>
						{{ day }}
					</button>
			
				</div>
				<div class="close-btn" v-if="!alwaysVisible">
					<button class="btn" @click="showCalendar = false">Done</button>
				</div>
			</section>
		</transition>

		<input type="text" name="dates" :id="name" :name="name" :value="valueFormatted" readonly @click="showCalendar = !showCalendar" :required="required" v-if="alwaysVisible" class="alwaysVisible">

	</div>
</template>

<script>

const date = new Date()
const currentMonth = date.getMonth()
const currentYear = date.getFullYear()
const currentDay = date.getDate()
export default {
	name: 'FatPickr',
	props: {
		value: {
			type: Array,
			default: []
		},
		label: {
			type: String,
			default: 'Please Choose a Date(s)'
		},
		required: {
			type: Boolean,
			default: false
		},
		holidayArr: {
			type: Array,
			default: []
		},
		name: {
			type: String,
			default: ''
		},
		alwaysVisible: {
			type: Boolean,
			default: false
		},
		range: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			showCalendar: false,
			selectedMonthIndex: 0,
			selectedYear: 0,
			days: [],
			months: [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December",
				]
		}
	},
	methods: {
		makeDateString(y, m, d){
			const month = m <= 9 ? `0${m}` : m
			const day = d <= 9 ? `0${d}` : d
			return `${y}-${month}-${day}`
		},
		setDays(){
			this.days = new Array(this.numberOfDays)
									.fill(undefined)
									.map((_, index) => ( index+1 ) )
		},
		handleNextMonth() {
			if(this.selectedMonthIndex === 11){
				this.selectedMonthIndex = 0
				this.selectedYear++
			}else{
				this.selectedMonthIndex++
			}
		},
		handlePrevMonth() {
			if(this.selectedMonthIndex === 0){
				this.selectedMonthIndex = 11
				this.selectedYear--
			}else{
				this.selectedMonthIndex--
			}
		},
		handleDayClick(day) {
			const dateToAdd = this.makeDateString(this.selectedYear, this.selectedMonthIndex+1, day)
			const currentArr = [...this.value]
			let newArr;

			if(currentArr.includes(dateToAdd)){
				newArr = currentArr.filter(item => item !== dateToAdd)
			}else{
				newArr = [...currentArr, dateToAdd]
			}
			newArr.sort();

			this.$emit('update:value', newArr)
		},
		isPicked(day){
			const dateString = this.makeDateString(this.selectedYear, this.selectedMonthIndex+1, day)
			return this.value.includes(dateString)
		},
		isWeekend(day){
			const dateString = `${this.selectedMonthIndex + 1}/${day}/${this.selectedYear}`
			const dayIndex = new Date(dateString).getDay()
			return dayIndex === 0 || dayIndex === 6
		},
		isHoliday(day){
			const dateString = this.makeDateString(this.selectedYear, this.selectedMonthIndex+1, day)
			return this.holidayArr.includes(dateString)
		},
		isToday(day) {
			return ((day === currentDay) && (this.selectedMonthIndex === currentMonth))
		},
		isNotInRange(day) {
			if(this.range > 0) {
				let rangeOfDates = [];
				const currDate = new Date();
			    for (var i = 0; i <= this.range; i++) {
			        var checkDate = new Date();
			        checkDate.setDate(currDate.getDate() + i);
			        rangeOfDates.push(this.makeDateString(checkDate.getFullYear(), checkDate.getMonth()+1, checkDate.getDate()));
			    }
			    const dateString = this.makeDateString(this.selectedYear, this.selectedMonthIndex+1, day)
			    return ! rangeOfDates.includes(dateString)
			} else {
				return ! (((day >= currentDay) && (this.selectedMonthIndex == currentMonth) && (this.selectedYear == currentYear)) || this.selectedMonthIndex != currentMonth || this.selectedYear != currentYear)
			}
		}
	},
	computed: {
		selectedMonthText(){
			return this.months[this.selectedMonthIndex]
		},
		numberOfDays(){
			return new Date(this.selectedYear, this.selectedMonthIndex+1, 0).getDate();
		},
		leadingSpan(){
			return new Date(this.selectedYear, this.selectedMonthIndex).getDay()
		},
		valueFormatted(){
			return [...this.value].join(', ')
		},
		prevMonthActive(){
			return ! (this.selectedMonthIndex <= currentMonth && this.selectedYear <= currentYear)
		},
		nextMonthActive(){
			return true
		}
	},
	watch: {
		selectedMonthIndex(){
			this.setDays()
		}
	},
	mounted() {
		this.selectedMonthIndex = currentMonth
		this.selectedYear = currentYear
		this.setDays()
	},
}
</script>

<style lang="scss" scoped>

.fatpickr{
	position: relative;
	margin: 2rem 0;
}

input{
	width: 100%;
	font-size: 1rem;
	padding: 0.5rem;
	background: #fff;
	border: solid 2px #fff;
	height: 45px;
	display: block;
	transition: all 300ms;

		&:focus{
			border-color: var(--orange);
			outline: solid 1px var(--orange);
			outline-offset: 3px;
		}

		&:holiday{
			border-color: var(--yellow);
			background: #000;
			color: #fff;
			opacity: 0.7;
		}

	&.alwaysVisible {
		background: transparent;
		color: #fff;
		border: solid 2px transparent;
		pointer-events: none;
		text-overflow: ellipsis;
		overflow: hidden;
	}

}

.calendar{
	display: block;
	min-width: 280px;
	max-width: 100%;
	background: #111111;
	padding: 10px;
	box-shadow: 0 1px 5px rgba(#fff, .2);
	border-radius: 10px;
	position: absolute;
	top: 100%;
	z-index: 100;
	&.alwaysVisible { 
		position: static; 
		top: auto; 
		max-width: 320px;
	}
}

.month-row{
	display: flex;
	width: 100%;

	.selected-month{
		flex: 1 1 auto;
		display: block;
		text-align: center;

		h3{
			margin: 5px;
		}

		h4{
			margin: 0;
			font-size: .8rem;
		}
	}

	button{
		flex: 0 0 45px;
		display: block;
		border: solid 2px #fff;
		color: #fff;
		background: none;
		border-radius: 12px;
		font-size: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}

.days-grid{
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	margin-top: 10px;
	width: 100%;
	max-width: 100%;

	*{
		margin: 0;
		text-align: center;
	}

	p{
		font-size: 0.8rem;
		color: var(--orange);
	}

	.day{
			padding: 10px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1rem;
			position: relative;
			border-radius: 12px;
			cursor: pointer;
			background: none;
			color: #fff;
			border: transparent 2px solid;
			transition: all 100ms;

			&:hover {
				border-color: #fff;
			}

			&.picked{
				background: var(--orange);
				color: #fff;

				&.weekend{
					color: #000;
					background: var(--yellow);
				}
				&.holiday{
					color: #000;
					background: var(--yellow);
				}
			}

			&.weekend{
				color: var(--yellow);
			}

			&.holiday{
				color: var(--yellow);
				font-weight: 900;
			}

			&.today { text-decoration: underline; }

			&.inactive { 
				color: #ccc !important;
				opacity: 0.2;
				pointer-events: none;
				border: solid 2px transparent !important;
			}

		}
}

.spacer{
	grid-column: span var(--spacer, 0);
}


.close-btn{
	border-top: solid #555 1px;
	margin-top: 15px;
	
	button.btn{
		width: 100%;
		border-radius: 12px;
	}
}


.overlay{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 95;
}

.month-btn {
	opacity: 0.1;
	pointer-events: none;
	&.active { opacity: 1; pointer-events: all; }
}

.toggle-calendar-enter-active, .toggle-calendar-leave-active {
	transition: all 200ms;
	transition-timing-function: none;
}
.toggle-calendar-enter-from, .toggle-calendar-leave-to {
	opacity: 0;
	transform: translateY(25px);
}
</style>