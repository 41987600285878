<template>
	<h2>Do you have an active account with Flagger Force?</h2>
	<div class="grid col-3">
		<button @click="handleClick('yes')" class="btn lighter">Yes</button>
		<button @click="handleClick('no')" class="btn">No</button>
		<router-link to="/notsure" class="btn darker">I Don't Know</router-link>
	</div>

	<ContinueBtn to="/date" :isVisible="false" backTo="/" justBack />
</template>

<script>
import ContinueBtn from '@/components/ContinueBtn.vue'
export default {
	components:{
		ContinueBtn
	},
	methods: {
		handleClick(val){
			this.$store.state.fields.has_active_account = val
			if(val == 'no') { this.$store.state.isRequestingQuote = true; }
			this.$router.push('/date')
		}
	},
	mounted(){
		this.$store.state.visited = 0;
	}
}
</script>

<style lang="scss" scoped>
	h2{
		text-align: center;
	}
.btn {
  display: block;
  width: 100%;
  text-align: left;
  margin: 15px 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 8px;
  max-width: 500px;

	&:hover{
		background: var(--yellow);
	}
}

button.lighter { background: #f98f00; }

.btn.darker { background: #a02000; }
</style>