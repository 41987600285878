<template>
	<h2>Scope Of Work</h2>

	<TextArea
		v-model:value="fields.scopeOfWork.workDescription"
		placeholder="Wire Pull, Directional Drilling, Tree Trimming, etc. If you can, please include how this work could impact road travel."
		label="WORK DESCRIPTION"
		:required="true" />
	
	<Checkbox
		label="Please indicate if your work includes"
		name="indicate-if-your-work-includes"
		:options="['Concrete/Asphalt/Paving', 'Helicopter', 'Other Scheduled Resources']"
		:required="true"
		:includeNoneOfTheAbove="true"
		v-model:value="fields.scopeOfWork.indicateIfYourWorkIncludes" />


	<Input 
		label="Please Specify Other Scheduled Resources (Comma separated list)"
		placeholder="item 1, item 2, etc."
		v-model:value="fields.scopeOfWork.additionalScheduledResources"
		v-if="fields.scopeOfWork.indicateIfYourWorkIncludes.includes('Other Scheduled Resources')" />

	<YesNo
		label="Is this a prevailing wage job?"
		v-model:value="fields.scopeOfWork.prevailingWageJob"
		:required="true" />

	<ContinueBtn to="/Company" :isVisible="showBtn" />
</template>

<script>
import Input from '@/components/Input.vue'
import TextArea from '@/components/TextArea.vue'
import Checkbox from '@/components/Checkbox.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'
import { mapState } from 'vuex'

export default {
	name: 'ScopeOfWork',
	components: {
		TextArea,
		Checkbox,
		Input,
		ContinueBtn
	},
	computed: {
		...mapState(['fields']),
		showBtn(){
			let showBtn = true

			if(this.fields.scopeOfWork.workDescription === '') showBtn = false
			if(this.fields.scopeOfWork.indicateIfYourWorkIncludes.length === 0) showBtn = false

			return showBtn
		}
	},
	mounted() {
		this.$store.commit('addRoute', 3)
	},
}
</script>