<template>
	<button
		:class="{ active: value }"
		@click="$emit('btnClick')">
			<svg width="25" height="23.529" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="1.5" y="6.5" width="9" height="9" stroke="black" stroke-width="2" />
				<path d="M1 8.5L6 12.5L15.5 1" stroke="#FFF200" stroke-width="2" stroke-linejoin="round" class="check" :class="{ active: value }"/>
			</svg>
			<span>{{ sameAsText }}</span>
	</button>
</template>

<script>
export default {
	name: 'SameAsBtn',
	props: {
		sameAsText: String,
		value: Boolean,
	}
}
</script>

<style lang="scss" scoped>
	button{
		--stroke-color: var(--yellow);
		border: none;
		background: var(--orange);
		color: #000;
		text-align: left;
		display: flex;
		align-items: center;
		padding: 13px 25px 13px 50px;
		position: relative;
		cursor: pointer;
		margin-top: 15px;
		font-size: 0.8rem;
		display: block;
		width: 100%;
		transition: all 300ms;

		@media screen and (min-width: 480px){
			width: auto;
		}


		&:hover{
			--stroke-color: #000;
			background: var(--yellow);
		}
	}

	svg{
		position: absolute;
		top: 6px;
		left: 10px;

		.check{
			stroke: var(--stroke-color);
			stroke-dasharray: 25;
			stroke-dashoffset: 25;
			transition: stroke-dashoffset 300ms, stroke 300ms;

			&.active{
				stroke-dashoffset: 0;
			}
		}
	}
</style>