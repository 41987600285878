<template>
	<h2>Contact</h2>
	<h3>Person Placing This Order</h3>
	<Input 
		label="First Name"
		name="firstName"
		v-model:value="fields.contact.personPlacingThisOrder.firstName"
		:required="true" />


	<Input 
		label="Last Name"
		name="lastName"
		v-model:value="fields.contact.personPlacingThisOrder.lastName"
		:required="true" />


	<Input 
		label="phone"
		name="phone"
		type="phone"
		v-model:value="fields.contact.personPlacingThisOrder.phone"
		:required="true" />


	<Input 
		label="email"
		name="email"
		type="email"
		v-model:value="fields.contact.personPlacingThisOrder.email"
		:required="true" />

	<YesNo 
		label="Are you the on-site foreman or jobsite contact?"
		name="areYouTheForemanOrJobsiteContact"
		v-model:value="fields.contact.areYouTheForemanOrJobsiteContact" />

	<div v-if="fields.contact.areYouTheForemanOrJobsiteContact === 'no'">

		<h3>Job Site Contact</h3>
	
		<Input 
			label="First Name"
			name="jobSiteFirstName"
			v-model:value="fields.contact.jobSiteContact.firstName"
			:required="true" />


		<Input 
			label="Last Name"
			name="jobSiteLastName"
			v-model:value="fields.contact.jobSiteContact.lastName"
			:required="true" />


		<Input 
			label="phone"
			name="jobSitePhone"
			type="phone"
			v-model:value="fields.contact.jobSiteContact.phone"
			:required="true" />


		<Input 
			label="email"
			name="jobSiteEmail"
			type="email"
			v-model:value="fields.contact.jobSiteContact.email"
			:required="true" />
	</div>

	<ContinueBtn to="/review" :isVisible="showBtn" />

</template>

<script>
import YesNo from '@/components/YesNo.vue'
import Input from '@/components/Input.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'
import { mapState } from 'vuex'

export default {
	name:'Contact',
	components: {
		Input,
		YesNo,
		ContinueBtn
	},
	computed:{
		...mapState(['fields']),
		showBtn(){
			let showBtn = true

			if(this.fields.contact.personPlacingThisOrder.firstName === '') showBtn = false
			if(this.fields.contact.personPlacingThisOrder.lastName === '') showBtn = false
			if(this.fields.contact.personPlacingThisOrder.phone === '') showBtn = false
			if(this.fields.contact.personPlacingThisOrder.email === '') showBtn = false

			if(this.fields.contact.areYouTheForemanOrJobsiteContact === 'no'){
				if(this.fields.contact.jobSiteContact.firstName === '') showBtn = false
				if(this.fields.contact.jobSiteContact.lastName === '') showBtn = false
				if(this.fields.contact.jobSiteContact.phone === '') showBtn = false
				if(this.fields.contact.jobSiteContact.email === '') showBtn = false
			}

			return showBtn
		}
	},
	mounted(){
		this.$store.commit('addRoute', 5)
	}
}
</script>