<template>
  <div class="header-block">
    <h2>Need the Best-in-Class Traffic Control? We Can Help!</h2>
  </div>
  <div class="input-box">
    <p class="label">What can we help you with?</p>
    <div class="home-btns">
      <router-link to="/date" class="lighter btn">Place An Order</router-link>
      <router-link to="/cancelorder" class="darker btn">Cancel An Order</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Order'
}
</script>