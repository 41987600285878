<template>
		<div class="input-container" :class="additionalClasses">
			<p class="label">{{ label }} <span class="required" v-if="required">*</span></p>
			<div class="flex">
				<label :for="name">
					<input :type="type" :placeholder="placeholder" :id="name" :name="name" :value="[sameAsActive ? sameValue : value]" @input="$emit('update:value', $event.target.value)" :disabled="sameAsActive">
				</label>
				<SameAsBtn
					@btnClick="handleSameBtn"
					:value="sameAsActive"
					:sameAsText="sameBtnText"
					v-if="sameBtn"/>
			</div>

		</div>

</template>


<script>
	import SameAsBtn from './SameAsBtn.vue'
	export default {
		name: 'Input',
		components: {
			SameAsBtn
		},
		props:{
			name: String,
			label: String,
			placeholder: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				default: 'text'
			},
			required: {
				type: Boolean,
				default: false
			},
			value: String,
			sameBtn:{
				type: Boolean,
				default: false
			},
			sameBtnText:{
				type: String,
				default: 'Previous Input'
			},
			sameValue:{
				type: String,
				required: false
			},
			sameAsActiveExternal: {
				type: Boolean,
				default: false
			},
			additionalClasses: {
				type: String,
				required: false,
				default: ''
			}
		},
		data() {
			return {
				sameAsActive: false
			}
		},
		mounted(){
			this.sameAsActive = this.sameAsActiveExternal || false
		},
		methods: {
			handleSameBtn(){
				//If setting same to false then keep the value
				if(this.sameAsActive) this.$emit('update:value', this.sameValue)

				this.sameAsActive = !this.sameAsActive
			}
		},
		watch: {
			sameAsActiveExternal: function(){
				if(!this.sameAsActiveExternal) this.$emit('update:value', this.sameValue)
				this.sameAsActive = this.sameAsActiveExternal
			}
		}
	}
</script>


<style lang="scss" scoped>

	.input-container{
		width: 100%;
		margin: 2rem auto;

		&.no-margin{
			margin: 0;
		}
	}

	.label{
		margin-bottom: 10px;
		display: block;
		font-family: var(--font-condensed);
		text-transform: uppercase;
	}

	input{
		width: 100%;
		font-size: 1rem;
		padding: 0.5rem;
		background: #fff;
		border: solid 2px #fff;
		height: 45px;
		display: block;
		transition: all 300ms;


		&:focus{
			border-color: var(--orange);
			outline: solid 1px var(--orange);
			outline-offset: 3px;
		}

		&:disabled{
			border-color: var(--yellow);
			background: #000;
			color: #fff;
			opacity: 0.7;
		}
	}

	.btn.sameAsActive{
		background: var(--yellow);
	}

	.flex{

		@media screen and (min-width: 767px){
			display: flex;
			gap: 20px;
		}

		label{
			flex: 1 1 100%;
		}

		button{
			flex: 0 0 auto;
			@media screen and (min-width: 767px){
				margin-right: 10px;
				margin-top: 0;
			}

			@supports (gap: 10px){
				margin-right: 0;
			}
		}
	}
</style>