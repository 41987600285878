<template>
  <div class="header-block">
    <h2>Need the Best-in-Class Traffic Control? We Can Help!</h2>
  </div>
  <div class="input-box">
    <div class="btn-options home-btns">
      <button @click="letsGo(false)" class="lighter btn">Place an Order</button>
      <!--button @click="letsGo(true)" class="btn">Request A Quote</button-->
      <router-link to="/request-a-quote" class="btn">Request A Quote</router-link>
      <router-link to="/notsure" class="darker btn">I need help - Let's Talk!</router-link>
    </div>
  </div>
  <p>If you need more information about placing an order, setting up an 
account or help with an existing order or anything else, please call us to 
talk to one of our team members. Our specialists are available 24/7 at <strong><a href="tel:+18883123524">888.312.3524</a></strong></p>

</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      sameAsPersonPlacingOrderBool: false,
      sameAsLocationOfWorkBool: false,
    }
  },
  methods:{
    letsGo(isRequestingQuote){
      this.$store.state.isRequestingQuote = isRequestingQuote
      if(!isRequestingQuote) { 
        this.$router.push('/has-account') 
      } else { 
        this.$store.state.fields.has_active_account = false
        this.$router.push('/date')
      }
    }
  },
  watch: {
    'fields.indicateIfYourWorkIncludes': function(newValue, oldValue) {
      if( !oldValue.includes('None Of The Above') && newValue.includes('None Of The Above') ){
        this.fields.indicateIfYourWorkIncludes = ['None Of The Above']
      }else if(newValue.length > 1 && newValue.includes('None Of The Above')){
        this.fields.indicateIfYourWorkIncludes = newValue.filter(val => val !== 'None Of The Above')
      }
    }
  },
  mounted() {
    this.$store.commit('resetStuff')
  }
}
</script>

<style scoped lang="scss">

.home-btns .btn {
  display: block;
  width: 100%;
  text-align: left;
  margin: 15px 0;
  font-size: 1.2em;
  text-transform: uppercase;
  border-radius: 8px;
  max-width: 500px;
}

.btn.lighter { background: #f98f00; }

.btn.darker { background: #a02000; }
p{
  max-width: 500px;
  margin: 20px 0;

  a{
    color: var(--yellow);
  }
}
</style>