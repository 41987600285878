<template>

	<div class="header-block" v-if="submitted">
		<h2>Your Request Has Been Submitted!</h2>
		<h3 style="border:none;">
		  <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		    <path d="M12 7L12 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
		    <path d="M12 17.01L12.01 16.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
		    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
		  </svg> 
		  Your request is being reviewed.<br>
		  <small>You will receive an email or phone call with confirmation and order number.</small>
		</h3>
		<div class="home-btns">
			<router-link to="/date" class="btn" @click="$store.commit('resetStuff')">Make another request</router-link>
		</div>
		<p style="margin-top:20px;font-size:18px;color:#fff;">We value your feedback! Please click the "Give Us Your Feedback" button at the top of this page.</p>
	</div>

	<div v-if="!submitted">
		<h4 class="last" style="margin-bottom:15px;">Please Review All Of The fields Below Before Submitting.</h4><!--lol-->

		<button class="submit-all-btn btn" :class="{submitting}" @click="submitAll" v-if="fields.isChangeOrder === false">Submit All</button>
		<button class="submit-all-btn btn" :class="{submitting}" @click="submitAll" v-else>Confirm Request</button>
	</div>

	<div v-if="fields.isChangeOrder != false">
		
		<h3 v-if="submitted">Order Summary</h3>
		<h3 v-else>Please Confirm Your Change Request</h3>

		<p class="label">Flagger Force Order Number</p>
		<p> {{ fields.changeOrder.orderNumber }}</p>

		<div v-if="fields.isChangeOrder === 'cancel'">
			<p class="label">Reason for cancellation/notes</p>
			<p class="smaller">{{ fields.changeOrder.changeNotes }}</p>
		</div>

		<p class="label">Person Requesting Change</p>
		<p class="smaller"> {{ fields.changeOrder.changeFirstName }} {{ fields.changeOrder.changeLastName }}<br>
			{{ fields.changeOrder.changePhone }}<br>
			{{ fields.changeOrder.changeEmail }}<br>
			{{ fields.changeOrder.changeCompany }}
		</p>

		<div v-if="fields.isChangeOrder === 'modify'">
			<div v-if="fields.changeOrder.whatChange.includes('Date/time')">

				<h3>Dates/Times</h3>

				<p class="label">Dates Of Work</p>
				<p>
					<span class="block" v-for="date of fields.changeOrder.date.datesOfWork">{{ date }}</span>
				</p>

				<div class="grid col-2 col-1-md gap-none">
					<div>
						<p class="label">Start Time</p>
						<p>{{ fields.changeOrder.date.startTime }}</p>
					</div>

					<div>
						<p class="label">End Time</p>
						<p>{{ fields.changeOrder.date.endTime }}</p>
					</div>

				</div>

				<div v-if="fields.changeOrder.date.dateNotes != ''">
					<p class="label">Date/Time Notes</p>
					<p class="smaller">{{ fields.changeOrder.date.dateNotes }}</p>
				</div>
			</div>
			<div v-if="fields.changeOrder.whatChange.includes('Location')">

				<h3>Location</h3>

				<p class="label">Address Where Traffic Control Work Is Taking Place</p>
				<address>
					<span>{{ fields.changeOrder.location.addressIntersection.address }}</span>
					<span>{{ fields.changeOrder.location.addressIntersection.city }}, {{ fields.changeOrder.location.addressIntersection.state }} {{ fields.changeOrder.location.addressIntersection.zip }}</span>
				</address>

				<p class="label" v-if="fields.changeOrder.location.addressIntersection.additionalDetails">Additional Details For the Above Info</p>
				<p class="smaller" v-if="fields.changeOrder.location.addressIntersection.additionalDetails">{{ fields.changeOrder.location.addressIntersection.additionalDetails }}</p>

				<p class="label">Is Our Crew Meeting You At This Address?</p>
				<p>{{ fields.changeOrder.location.sameAsLocationOfWorkBool }}</p>

				<div v-if="fields.changeOrder.location.sameAsLocationOfWorkBool === 'no'">
					<p class="label">Where Should We Meet You?</p>
					<address>
						<span>{{ fields.changeOrder.location.preJobMeetingLocation.address }}</span>
						<span>{{ fields.changeOrder.location.preJobMeetingLocation.city }}, {{ fields.changeOrder.location.preJobMeetingLocation.state }} {{ fields.changeOrder.location.preJobMeetingLocation.zip }}</span>
					</address>
				</div>

				<p class="label" v-if="fields.changeOrder.location.preJobMeetingLocation.additionalDetails">Additional Details For the Above Info</p>
				<p class="smaller" v-if="fields.changeOrder.location.preJobMeetingLocation.additionalDetails">{{ fields.changeOrder.location.preJobMeetingLocation.additionalDetails }}</p>
				
			</div>
		</div>

		<div v-if="!submitted">
			<router-link to="/cancelorder" class="btn" v-if="fields.isChangeOrder === 'cancel'">Edit Change Request Details</router-link>
		</div>

	</div>
	<div v-else>
		<h2 v-if="!submitted">Review Your Order</h2>
	</div>

	<div v-if="fields.isChangeOrder === false">
		<h3>Dates</h3>

		<p class="label">Dates Of Work</p>
		<p>
			<span class="block" v-for="date of fields.date.datesOfWork">{{ date }}</span>
		</p>

		<div class="grid col-2 col-1-md gap-none">
			<div>
				<p class="label">Start Time</p>
				<p>{{ fields.date.startTime }}</p>
			</div>

			<div>
				<p class="label">End Time</p>
				<p>{{ fields.date.endTime }}</p>
			</div>

		</div>

		<div v-if="fields.date.dateNotes != ''">
			<p class="label">Date/Time Notes</p>
			<p class="smaller">{{ fields.date.dateNotes }}</p>
		</div>


		<router-link to="/date" class="btn" v-if="!submitted">Edit Dates</router-link>
	</div>

	<div v-if="fields.isChangeOrder === false">
		<h3>Scope Of Work</h3>
		
		<p class="label">Work Description</p>
		<p class="smaller">{{ fields.scopeOfWork.workDescription }}</p>

		<p class="label">You Indicated Your Work Includes</p>
		<p>
			<span class="block" v-for="item of allIndicatedInclusions">
				{{ item }}
			</span>
		</p>
		<router-link to="/scope-of-work" class="btn" v-if="!submitted">Edit Scope Of Work</router-link>
	</div>

	<div v-if="fields.isChangeOrder === false">
		<h3>Location</h3>

		<p class="label">Address Where Traffic Control Work Is Taking Place</p>
		<address>
			<span>{{ fields.location.addressIntersection.address }}</span>
			<span>{{ fields.location.addressIntersection.city }}, {{ fields.location.addressIntersection.state }} {{ fields.location.addressIntersection.zip }}</span>
		</address>

		<p class="label" v-if="fields.location.addressIntersection.additionalDetails">Additional Details For the Above Info</p>
		<p class="smaller" v-if="fields.location.addressIntersection.additionalDetails">{{ fields.location.addressIntersection.additionalDetails }}</p>

		<p class="label">Is Our Crew Meeting You At This Address?</p>
		<p>{{ fields.location.sameAsLocationOfWorkBool }}</p>

		<div v-if="fields.location.sameAsLocationOfWorkBool === 'no'">
			<p class="label">Where Should We Meet You?</p>
			<address>
				<span>{{ fields.location.preJobMeetingLocation.address }}</span>
				<span>{{ fields.location.preJobMeetingLocation.city }}, {{ fields.location.preJobMeetingLocation.state }} {{ fields.location.preJobMeetingLocation.zip }}</span>
			</address>
		</div>

		<p class="label" v-if="fields.location.preJobMeetingLocation.additionalDetails">Additional Details For the Above Info</p>
		<p class="smaller" v-if="fields.location.preJobMeetingLocation.additionalDetails">{{ fields.location.preJobMeetingLocation.additionalDetails }}</p>
		
		<router-link to="/location" class="btn" v-if="!submitted">Edit Location</router-link>
	</div>

	<div v-if="fields.isChangeOrder === false">
		<h3>Company</h3>

		<p class="label">Company Name</p>
		<p>{{ fields.company.companyName }}</p>

		<p class="label">Are You Performing Work As A subcontractor?</p>
		<p>{{ fields.company.areYouPerformingWorkAsSubcontractor }}</p>

		<div v-if="fields.company.areYouPerformingWorkAsSubcontractor === 'yes'">
			<p class="label">What Company Are You Performing Work For?</p>
			<p>{{ fields.company.whatCompanyAreYouPerformingWorkFor }}</p>
		</div>

		<p class="label">Company Being Billed</p>
		<p>{{ fields.company.nameOfTheCompanyBeingBilledForTrafficControlWork }}<span v-if="fields.company.nameOfTheCompanyBeingBilledForTrafficControlWork == 'Other'">: {{fields.company.companyBeingBilled}}</span></p>
		<p class="label" v-if="fields.company.jobProjectIdentifiers">Additional Job Details</p>
		<p class="smaller" v-if="fields.company.jobProjectIdentifiers">{{ fields.company.jobProjectIdentifiers }}</p>
		
		<router-link to="/company" class="btn" v-if="!submitted">Edit Company</router-link>
	</div>

	<div v-if="fields.isChangeOrder === false">
		<h3>Contact</h3>
		<div class="grid col-2-md gap-1">
		
			<div>
				<h4 style="margin-top:20px;">Person Placing This Order</h4><!--lol-->
				<p class="label">First Name</p>
				<p>{{ fields.contact.personPlacingThisOrder.firstName }}</p>
				<p class="label">Last Name</p>
				<p>{{ fields.contact.personPlacingThisOrder.lastName }}</p>
				<p class="label">Phone</p>
				<p>{{ fields.contact.personPlacingThisOrder.phone }}</p>
				<p class="label">Email</p>
				<p>{{ fields.contact.personPlacingThisOrder.email }}</p>
				<p class="label">Are You The Foreman Or Jobsite Contact?</p>
				<p>{{ fields.contact.areYouTheForemanOrJobsiteContact }}</p>
			</div>
		
			<div v-if="fields.contact.areYouTheForemanOrJobsiteContact === 'no'">
				<h4>Jobsite Contact</h4>
		
				<p class="label">First Name</p>
				<p>{{ fields.contact.jobSiteContact.firstName }}</p>
		
				<p class="label">Last Name</p>
				<p>{{ fields.contact.jobSiteContact.lastName }}</p>
		
				<p class="label">Phone</p>
				<p>{{ fields.contact.jobSiteContact.phone }}</p>
		
				<p class="label">Email</p>
				<p>{{ fields.contact.jobSiteContact.email }}</p>
		
			</div>
		</div>

		<router-link to="/contact" class="btn" v-if="!submitted">Edit Contact</router-link>
	</div>

	<div v-if="!submitted">
		<h4 class="last" style="margin-bottom:15px;">Please Review All Of The fields Above Before Submitting.</h4><!--lol-->

		<button class="submit-all-btn btn" :class="{submitting}" @click="submitAll" v-if="fields.isChangeOrder === false">Submit All</button>
		<button class="submit-all-btn btn" :class="{submitting}" @click="submitAll" v-else>Confirm Request</button>
	</div>

</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		allIndicatedInclusions: function(){
			let newArr = []
			if(this.fields.scopeOfWork.additionalScheduledResources != '') {
				newArr = this.fields.scopeOfWork.additionalScheduledResources.split(', ')
			}
			let oldArr = this.fields.scopeOfWork.indicateIfYourWorkIncludes

			return [...oldArr, ...newArr]
		},
		...mapState(['fields']),
	},
	methods: {
		submitAll: function() {

			let formData = new FormData();
			formData.append('fields', JSON.stringify(this.fields));

			let t = this;
			t.submitting = true;

			fetch('https://orders.flaggerforce.mindstaging.com/ff.php', { /* move this to env or change when this launches */
				method: 'post',
				body: formData
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				setTimeout(() => {
					t.submitted = true;
					t.submitting = false;
					window.scrollTo(0,0);
				}, 2000);
		  	});

		}
	},
	data(){
		return {
			submitted: false,
			submitting: false
		}
	},	
	mounted(){
		this.$store.commit('addRoute', 6)
	}
}
</script>

<style lang="scss" scoped>

	h3{
		font-size: 1.8rem;
		border-bottom: solid 2px #fff;
		padding: 10px 0;
		margin-top: 50px;

		&:first-of-type{
			margin-top: 1rem;
		}

		&.text-with-icon { border-bottom: none; }
	}

	h4{
		color: var(--orange);
	}

	p.label{
		font-size: 1rem;
		color: #fff;
		margin-bottom: 3px;
		margin-top: 2rem;
		font-family: var(--font-condensed);
		text-transform: uppercase;
	}

	p{
		font-size: 1.3rem;
		color: var(--yellow);

		&.smaller{
			font-size: 1rem;
			font-weight: normal;
		}
	}

	.block{
		padding: 5px 10px;
		border: solid 2px #fff;
		box-sizing: border-box;
		margin: 5px 10px 5px 0;
		display: inline-block;
		font-size: 1rem;
	}
	
	address{
		color: var(--yellow);
		font-size: 1rem;

		span{
			display: block;
		}
	}

	.btn{
		margin-top: 2rem;
	}

	.submit-all-btn{
		font-size: 1.5rem;
		margin: 0;

		&.submitting {
			color: var(--orange);
			position: relative;
			pointer-events: none;

			&:before {
				content: '';
				position: absolute;
				top: calc(50% - 9px);
				left: calc(50% - 9px);
				width: 18px;
				height: 18px;
				background:#fff;
				display: block;
				animation: spin 5s infinite linear;
			}

			&:after {
				content: '';
				position: absolute;
				top: calc(50% - 17px);
				left: calc(50% - 17px);
				width: 26px;
				height: 26px;
				border: solid 4px #fff;
				display: block;
				border-radius: 50%;
			}

		}

	}

	.last{
		margin-top: 50px;
		color: #fff;
		font-family: var(--font-condensed);
		font-size: 1.3rem;
	}

	@keyframes spin {
	    from {
	        transform:rotate(0deg);
	    }
	    to {
	        transform:rotate(360deg);
	    }
	}

</style>