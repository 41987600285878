<template>

	<div v-if="!dontGottaDate && !dontGottaPlace">

		<p>Let's get your account set up and work on some pricing with you.</p>
		<p>Do you have a targeted date for when you will need traffic control?</p>

		<div class="input-box">
			<div class="btn-options">
				<button @click="yesDate" class="btn">Yes</button>
				<button @click="dontGottaDate = true" class="btn">No</button>
			</div>
		</div>
	</div>

	<div v-if="dontGottaDate && !dontGottaPlace">
		<p>OK no problem. Do you have a location for your work?</p>

		<div class="input-box">
			<div class="btn-options">
				<button @click="yesLocation" class="btn">Yes</button>
				<button @click="dontGottaPlace = true" class="btn">No</button>
			</div>
		</div>
	</div>


	<form @submit.prevent v-if="dontGottaDate && dontGottaPlace">

		<p>OK, please provide us your contact information and we will reach out within one business day.</p>

		<fieldset class="grid mt">
			<Input type="text" label="First Name" required additionalClasses="no-margin" />
			<Input type="text" label="Last Name" required additionalClasses="no-margin" />
			<Input type="email" label="Email" required additionalClasses="no-margin" />
			<Input type="tel" label="Phone" required additionalClasses="no-margin" />
			<Input type="text" label="Company" required additionalClasses="no-margin" />
		</fieldset>

		<button class="btn">Submit</button>
	</form>

</template>

<script>
import Input from '@/components/Input.vue'
import TextArea from '@/components/TextArea.vue'

export default {
	name: 'RequestAQuote',
	components: {
		TextArea,
		Input
	},	
	data() {
		return {
			dontGottaDate: false,
			dontGottaPlace: false
		}
	},  
	methods:{
		yesDate(){
			this.$store.state.isRequestingQuote = true
			this.$store.state.fields.has_active_account = false
			this.$router.push('/date')
		},
		yesLocation(){
			this.$store.state.isRequestingQuote = true
			this.$store.state.fields.has_active_account = false
			this.$router.push('/location')
		}
	}
}
</script>

<style lang="scss" scoped>

fieldset{
	border: none;
}

.mt{
	margin-top: 50px;
}

.btn-options {
	display: flex;
	gap: 10px;
	.btn { 
		width: 100px; 
		font-size: 1.2em;
	}
}
.grid { padding-left: 0; padding-right: 0; }

</style>