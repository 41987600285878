import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Order from '../views/Order.vue'
import Temp from '../views/Temp.vue'
import Date from '../views/Date.vue'
import ScopeOfWork from '../views/Scope-Of-Work.vue'
import Location from '../views/Location.vue'
import Company from '../views/Company.vue'
import Contact from '../views/Contact.vue'
import Review from '../views/Review.vue'
import Modify from '../views/Modify.vue'
import CancelOrder from '../views/CancelOrder.vue'
import NotSure from '../views/NotSure.vue'
import HasAccount from '../views/HasAccount.vue'
import RequestAQuote from '../views/RequestAQuote.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },  
  {
    path: '/has-account',
    name: 'HasAccount',
    component: HasAccount
  },  
  {
    path:'/temp',
    name: 'Temp',
    component: Temp
  },
  {
    path:'/date',
    name: 'Date',
    component: Date
  },
  {
    path:'/scope-of-work',
    name: 'ScopeOfWork',
    component: ScopeOfWork
  },
  {
    path:'/location',
    name: 'Location',
    component: Location
  },
  {
    path:'/company',
    name: 'Company',
    component: Company
  },
  {
    path:'/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path:'/review',
    name: 'Review',
    component: Review
  },
  {
    path: '/modify',
    name: 'Modify',
    component: Modify
  },
  {
    path: '/cancelorder',
    name: 'CancelOrder',
    component: CancelOrder
  },
  {
    path: '/notsure',
    name: 'NotSure',
    component: NotSure
  },  
  {
    path: '/request-a-quote',
    name: 'RequestAQuote',
    component: RequestAQuote
  },  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
