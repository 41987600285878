<template>
  <div class="wrap">
    <h1>Place Your Order</h1>
    <FAQ />

    <form @submit.prevent="handleSubmit">
      <Input label="Client being billed" name="client-being-billed"  :required="true" v-model:value="fields.clientBeingBilled" />
      <Input
        label="Utility Receiving Service"
        name="utility-receiving-service"
        :required="true"
        v-model:value="fields.utilityReceivingService"
        :sameBtn="true"
        sameBtnText="Client Being Billed"
        :sameValue="fields.clientBeingBilled" />
      <Input
        label="Organization performing work"
        name="organization-performing-work"
        :required="true"
        v-model:value="fields.organizationPerformingWork"
        :sameBtn="true"
        sameBtnText="Client Being Billed"
        :sameValue="fields.clientBeingBilled" />
      <h2>Person Placing This Order</h2>
      <Input
        label="First Name"
        name="person-placing-this-order-first-name"
        :required="true"
        v-model:value="fields.personPlacingThisOrder.firstName" />
      <Input
        label="Last Name"
        name="person-placing-this-order-last-name"
        :required="true"
        v-model:value="fields.personPlacingThisOrder.lastName" />
      <Input
        label="Phone"
        name="person-placing-this-order-phone"
        type="phone"
        :required="true"
        v-model:value="fields.personPlacingThisOrder.phone" />
      <Input
        label="Email"
        name="person-placing-this-order-email"
        type="email"
        :required="true"
        v-model:value="fields.personPlacingThisOrder.email" />
      <h2>Foreman/Jobsite Contact</h2>

      <SameAsBtn
        @btnClick="sameAsPersonPlacingOrderBool = !sameAsPersonPlacingOrderBool"
        :value="sameAsPersonPlacingOrderBool"
        sameAsText="Same As Person Placing Order" />

      <Input
        label="First Name"
        name="jobsite-contact-first-name"
        :required="true"
        v-model:value="fields.jobsiteContact.firstName"
        :sameValue="fields.personPlacingThisOrder.firstName"
        :sameAsActiveExternal="sameAsPersonPlacingOrderBool" />
      <Input
        label="Last Name"
        name="jobsite-contact-last-name"
        :required="true"
        v-model:value="fields.jobsiteContact.lastName"
        :sameValue="fields.personPlacingThisOrder.lastName"
        :sameAsActiveExternal="sameAsPersonPlacingOrderBool" />
      <Input
        label="Phone"
        name="jobsite-contact-phone"
        type="phone"
        :required="true"
        v-model:value="fields.jobsiteContact.phone"
        :sameValue="fields.personPlacingThisOrder.phone"
        :sameAsActiveExternal="sameAsPersonPlacingOrderBool" />
      <Input
        label="Email"
        name="jobsite-contact-email"
        type="email"
        :required="true"
        v-model:value="fields.jobsiteContact.email"
        :sameValue="fields.personPlacingThisOrder.email"
        :sameAsActiveExternal="sameAsPersonPlacingOrderBool" />


      <h2>Date(s) Of Work</h2>
      <h3 class="text-with-icon">
        <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 7L12 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 17.01L12.01 16.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> 
        Only select the specific days you plan to work 
      </h3>
      
      <div class="date-contain">
        <p class="label">Date(s) Of Work</p>
        <flatPickr
          v-model="datesOfWork"
          :config="{mode: 'multiple'}" />
      </div>

      <TimePicker 
        name="startTime"
        label="Start Time"
        :required="true"
        v-model:value="fields.startTime" />

      <TimePicker 
        name="endTime"
        label="End Time"
        :required="true"
        v-model:value="fields.endTime" />


      <h2>Jobsite Details</h2>
      <h3>Address/Intersection</h3>
      
      <Input
        label="Address"
        name="address-intersection-address"
        placeholder="Intersection of Market St + Main St, Palmyra"
        :required="true"
        v-model:value="fields.addressIntersectionAddress" />
      <Input
        label="City"
        name="address-intersection-city"
        placeholder="Palmyra"
        :required="true"
        v-model:value="fields.addressIntersectionCity" />
      
      
      <Input
        label="State"
        name="address-intersection-state"
        :required="true"
        placeholder="Pennsylvania"
        v-model:value="fields.addressIntersectionState" />
      <Input
        label="Zip"
        name="address-intersection-Zip"
        :required="true"
        placeholder="17078"
        v-model:value="fields.addressIntersectionZip" />
        <YesNo
          v-model:value="fields.isUtilityServiceCenterOrYard"
          question="Is this location a utility service center or yard?"
          name="is-utility-service-center-or-yard"
          :required="true" />
      
      <h3>Pre-Job Meeting Location</h3>

      <SameAsBtn
        @btnClick="sameAsLocationOfWorkBool = !sameAsLocationOfWorkBool"
        :value="sameAsLocationOfWorkBool"
        sameAsText="Same As Location Of Work" />

      <Input
        label="Address"
        name="address-intersection-address"
        placeholder="Intersection of Market St + Main St, Palmyra"
        :required="true"
        v-model:value="fields.preJobMeetingLocationAddress"
        :sameValue="fields.addressIntersectionAddress"
        :sameAsActiveExternal="sameAsLocationOfWorkBool"/>
      <Input
        label="City"
        name="address-intersection-city"
        placeholder="Palmyra"
        :required="true"
        v-model:value="fields.preJobMeetingLocationCity"
        :sameValue="fields.addressIntersectionCity"
        :sameAsActiveExternal="sameAsLocationOfWorkBool" />
      
      
      <Input
        label="State"
        name="address-intersection-state"
        :required="true"
        placeholder="Pennsylvania"
        v-model:value="fields.preJobMeetingLocationState"
        :sameValue="fields.addressIntersectionState"
        :sameAsActiveExternal="sameAsLocationOfWorkBool" />
      <Input
        label="Zip"
        name="address-intersection-Zip"
        :required="true"
        placeholder="17078"
        v-model:value="fields.preJobMeetingLocationZip"
        :sameValue="fields.addressIntersectionZip"
        :sameAsActiveExternal="sameAsLocationOfWorkBool" />
      <TextArea
        v-model:value="fields.workDescription"
        placeholder="Wire Pull, Directional Drilling, Tree Trimming, etc. If you can, please include how this work could impact road travel."
        label="WORK DESCRIPTION"
        :required="true" />
      
      <Checkbox
        label="Please indicate if your work includes"
        name="indicate-if-work-includes"
        :options="['Concrete/Asphalt/Paving', 'Helicopter', 'Other Scheduled Resources', 'None Of The Above']"
        :required="true"
        v-model:value="fields.indicateIfYourWorkIncludes" />
      <Input
        label="PLEASE SPECIFY OTHER SCHEDULED RESOURCES"
        name="other-scheduled-resources"
        :required="false"
        placeholder="item 1, item 2"
        v-model:value="fields.otherScheduledResources"
        v-if="fields.indicateIfYourWorkIncludes.includes('Other Scheduled Resources')" />
      <h2>Billing Information</h2>
      <h3>Providing accurate billing details ensures the continuous quality of our service.</h3>
      <Input
        label="Job / Project Identifiers "
        name="job-project-identifiers"
        :required="false"
        placeholder="Work Order #s, Project IDs, etc."
        v-model:value="fields.jobProjectIdentifiers" />
        <h3>Billing Contact</h3>
        <p>Person or contact we can contact to clarify billing details.</p>
      <Input
        label="First Name"
        name="billing-contact-first-name"
        :required="true"
        v-model:value="fields.billingContact.firstName" />
      <Input
        label="Last Name"
        name="billing-contact-last-name"
        :required="true"
        v-model:value="fields.billingContact.lastName" />
      <Input
        label="Phone"
        name="billing-contact-phone"
        type="phone"
        :required="true"
        v-model:value="fields.billingContact.phone" />
      <Input
        label="Email"
        name="billing-contact-email"
        type="email"
        :required="true"
        v-model:value="fields.billingContact.email" />

        
      <button class="btn submit-btn" type="submit">Submit</button>
    </form>




  </div>
</template>

<script>
import FAQ from '@/components/FAQ.vue'
import Input from '@/components/Input.vue'
import YesNo from '@/components/YesNo.vue'
import TextArea from '@/components/TextArea.vue'
import Checkbox from '@/components/Checkbox.vue'
import SameAsBtn from '@/components/SameAsBtn.vue'
import TimePicker from '@/components/TimePicker.vue'
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'Temp',
  components:{
    FAQ,
    Input,
    YesNo,
    TextArea,
    Checkbox,
    flatPickr,
    TimePicker,
    SameAsBtn,
  },
  data() {
    return {
      test: false,
      fields:{
        clientBeingBilled: '',
        utilityReceivingService: '',
        organizationPerformingWork: '',
        personPlacingThisOrder: {
          firstName:'',
          lastName: '',
          phone: '',
          email: ''
        },
        jobsiteContact: {
          firstName:'',
          lastName: '',
          phone: '',
          email: ''
        },
        datesOfWork: '',
        startTime: '',
        endTime: '',
        addressIntersectionAddress: '',
        addressIntersectionCity: '',
        addressIntersectionState: '',
        addressIntersectionZip: '',
        isUtilityServiceCenterOrYard: '',
        preJobMeetingLocationAddress: '',
        preJobMeetingLocationCity: '',
        preJobMeetingLocationState: '',
        preJobMeetingLocationZip: '',
        workDescription: '',
        indicateIfYourWorkIncludes: [],
        otherScheduledResources: '',
        jobProjectIdentifiers: '',
        billingContact: {
          firstName:'',
          lastName: '',
          phone: '',
          email: ''
        },
      },
      sameAsPersonPlacingOrderBool: false,
      sameAsLocationOfWorkBool: false,
    }
  },
  watch: {
    'fields.indicateIfYourWorkIncludes': function(newValue, oldValue) {
      if( !oldValue.includes('None Of The Above') && newValue.includes('None Of The Above') ){
        this.fields.indicateIfYourWorkIncludes = ['None Of The Above']
      }else if(newValue.length > 1 && newValue.includes('None Of The Above')){
        this.fields.indicateIfYourWorkIncludes = newValue.filter(val => val !== 'None Of The Above')
      }
    }
  }
}
</script>

<style lang="scss">

  form{
    padding-bottom: 5rem;
  }

p{
  margin: 0;
}

h2{
  color: var(--yellow);
}

.btn.active{
  background: var(--yellow);
}
</style>
