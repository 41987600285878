<template>
		<div class="flex">
			<router-link class="btn back-btn" :to="backTo">
				<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentcolor"><path d="M18.5 12H6m0 0l6-6m-6 6l6 6" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
				Back
			</router-link>
			<transition name="show-btn" v-if="!justBack">
				<router-link :to="$store.state.fields.isChangeOrder !== false ? '/review' : $store.state.visited >= 6 ? '/review' : to"
					class="btn continue-btn" v-if="isVisible">
						{{ $store.state.fields.isChangeOrder !== false ? 'Continue' : $store.state.visited >= 6 ? 'Return To Review' : 'Continue' }}
					<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6 12H18.5M18.5 12L12.5 6M18.5 12L12.5 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</router-link>
				<div v-else>
					<h3 class="smaller text-with-icon">
							<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 7L12 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M12 17.01L12.01 16.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							Complete all required fields to continue.
						</h3>
				</div>
			</transition>
		</div>
</template>
<script>
export default {
	name: 'ContinueBtn',
	props: {
		to: String,
		backTo: {
			type: String,
			default: '/'
		},
		isVisible: {
			type: Boolean,
			default: true
		},
		justBack: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss">

.smaller.text-with-icon { font-size: 14px; font-weight: normal; margin: 30px 0 0; padding: 10px 0; }
.smaller.text-with-icon svg { width: 20px; height: 20px; }
.flex{
	display: flex;
	gap: 15px;
}
.continue-btn, .back-btn{
	display: flex;
	align-items: center;
	flex: 0 0 auto;

	svg{
		transition: all 300ms;
	}
	
	&:hover{
		
		svg{
			transform: translateX(10px);
		}
	}
}

.back-btn:hover svg{
	transform: translateX(-10px);
}

.show-btn-enter-active, .show-btn-leave-active {
	transition: all 300;
}
.show-btn-enter-from, .show-btn-leave-to {
	transform: translateX(-20px);
	opacity: 0;
}

</style>