<template>
  <div class="header-block">
    <h2>To check on your client status, please call.</h2>
		<p>If you need more information about placing an order, setting up an account or help with an existing order or anything else, please call us to talk to one of our team members: <strong>888.312.3524</strong></p>
    <div class="call-link-contain"><a href="tel:+18883123524" class="call-link">888.312.3524 <span>Tap to Call</span></a></div>
  </div>  
</template>

<script>

export default {
  name: 'ChangeLocation'
}
</script>

<style lang="scss" scoped>
	h2{
		text-align: center;

		@media screen and (min-width: 680px) {
			text-align: left;
		}
	}
p { 
	text-align: center;
	line-height: 2;
	max-width: 680px;

	@media screen and (min-width: 680px) {
		text-align: left;
	}
 }

.call-link-contain{
	display: flex;
	justify-content: center;
	margin: 24px auto;

	@media screen and (min-width: 680px) {
		display: none;
	}
}
.call-link { 
	color: var(--yellow); 
	display: inline-block; 
	
	padding: 10px 20px; 
	font-size: 32px; 
	border: solid 1px #ccc; 
	border-radius: 4px; 
	text-decoration: none;
	text-align:  center;
	font-weight: 900;

	span { 
	 display: block; 
	 font-size: 0.6em; 
	 color: #ccc; 
	 font-weight: normal; 
 }
}

strong{
	display: none;

	@media screen and (min-width: 680px) {
		display: inline;
		color: var(--yellow);
		font-size: 2em;
	}
}
</style>