import { createStore } from 'vuex'

export const store = createStore({
  state: {
    isRequestingQuote: false,
    isWindowOpen: false,
		rating: null,
		moreDetails: null,
    visited: 0,
    fields:{
      has_active_account: null,
      date: {
        datesOfWork: '',
        startTime: "--:--",
        endTime: "--:--",
        timeSlot: null,
        dateNotes: ''
      },
      scopeOfWork: {
        workDescription:'',
        indicateIfYourWorkIncludes: '',
        additionalScheduledResources: '',
        prevailingWageJob: ''
      },
      location: {
        addressIntersection:{
          address: '',
          city: '',
          state: '',
          zip: '',
          additionalDetails: ''
        },
        sameAsLocationOfWorkBool: 'yes',
        preJobMeetingLocation: {
          address: '',
          city: '',
          state: '',
          zip: '',
          additionalDetails: ''
        }
      },
      company: {
        companyName: '',
        areYouPerformingWorkAsSubcontractor: 'no',
        whatCompanyAreYouPerformingWorkFor: '',
        nameOfTheCompanyBeingBilledForTrafficControlWork: '',
        companyBeingBilled: '',
        areThereDetails: 'yes',
        jobProjectIdentifiers: ''
      },
      contact:{
        personPlacingThisOrder: {
          firstName: '',
          lastName:'',
          phone: '',
          email: '',
        },
        areYouTheForemanOrJobsiteContact: 'yes',
        jobSiteContact: {
          firstName: '',
          lastName:'',
          phone: '',
          email: '',
        }
      },
      isChangeOrder: false,
      changeOrder: {
        orderNumber: '',
        changeFirstName: '',
        changeLastName: '',
        changePhone: '',
        changeEmail: '',
        changeCompany: '',
        changeNotes: '',
        whatChange: [],
        date: {
          datesOfWork: '',
          startTime: '00:00',
          endTime: '00:00',
          dateNotes: ''
        },
        location: {
          addressIntersection:{
            address: '',
            city: '',
            state: '',
            zip: '',
            additionalDetails: ''
          },
          sameAsLocationOfWorkBool: 'yes',
          preJobMeetingLocation: {
            address: '',
            city: '',
            state: '',
            zip: '',
            additionalDetails: ''
          }
        },          
      }
    },
  },
  mutations: {
    updateField: (state, { key, newValue }) => state.fields[key] = newValue,
    addRoute: (state, newRoute) => {
      if(state.visited < newRoute) state.visited = newRoute
    },
    toggleFeedback: (state) => {
      state.isWindowOpen = !state.isWindowOpen
    },
    setRating: (state, newRating) => {
      state.rating = newRating
    },
    resetStuff: (state) => {
      state.visited = 0;
      state.fields = {
        date: {
          datesOfWork: '',
          startTime: '00:00',
          endTime: '00:00',
          dateNotes: ''
        },
        scopeOfWork: {
          workDescription:'',
          indicateIfYourWorkIncludes: '',
          additionalScheduledResources: '',
        },
        location: {
          addressIntersection:{
            address: '',
            city: '',
            state: '',
            zip: '',
            additionalDetails: ''
          },
          sameAsLocationOfWorkBool: 'yes',
          preJobMeetingLocation: {
            address: '',
            city: '',
            state: '',
            zip: '',
            additionalDetails: ''
          }
        },
        company: {
          companyName: '',
          areYouPerformingWorkAsSubcontractor: 'no',
          whatCompanyAreYouPerformingWorkFor: '',
          nameOfTheCompanyBeingBilledForTrafficControlWork: '',
          companyBeingBilled: '',
          areThereDetails: 'yes',
          jobProjectIdentifiers: ''
        },
        contact:{
          personPlacingThisOrder: {
            firstName: '',
            lastName:'',
            phone: '',
            email: '',
          },
          areYouTheForemanOrJobsiteContact: 'yes',
          jobSiteContact: {
            firstName: '',
            lastName:'',
            phone: '',
            email: '',
          }
        },
        isChangeOrder: false,
        changeOrder: {
          orderNumber: '',
          changeFirstName: '',
          changeLastName: '',
          changePhone: '',
          changeEmail: '',
          changeCompany: '',
          changeNotes: '',
          whatChange: [],
          date: {
            datesOfWork: '',
            startTime: '00:00',
            endTime: '00:00',
            dateNotes: ''
          },
          location: {
            addressIntersection:{
              address: '',
              city: '',
              state: '',
              zip: '',
              additionalDetails: ''
            },
            sameAsLocationOfWorkBool: 'yes',
            preJobMeetingLocation: {
              address: '',
              city: '',
              state: '',
              zip: '',
              additionalDetails: ''
            }
          },          
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})