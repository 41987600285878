<template>
  <Nav />
  <div class="wrap">
    <Breadcrumbs />
    <router-view />
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components:{
    Nav,
    Breadcrumbs,
  },
}
</script>