<template>
	<h5>Please Rate the Quality of Your Experience Placing Orders Via This Form</h5>
<div id="rating-row">
  <!-- VEY UNHAPPY BUTTON -->
  <button type="button" title="Rate Very Unhappy" @click="$store.commit('setRating', 'very unhappy')" :class="{chosen: rating === 'very unhappy'}">
    <svg width="40" height="40" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <g id="line">
        <circle cx="36" cy="36" r="23" fill="var(--head-fill, none)" stroke="currentcolor" stroke-miterlimit="10" stroke-width="3" />
        <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M26.5,48c1.8768-3.8326,5.8239-6.1965,10-6c3.8343,0.1804,7.2926,2.4926,9,6" />
        <path fill="var(--eye-fill, currentcolor)" d="M30,31c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,28,30,29.3448,30,31" />
        <path fill="var(--eye-fill, currentcolor)" d="M48,31c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,29.3448,48,31" />
      </g>
    </svg>
    <span>Very Unhappy</span>
  </button>

  <!-- UNHAPPY BUTTON -->
  <button type="button" title="Rate Unhappy" @click="$store.commit('setRating', 'unhappy')" :class="{chosen: rating === 'unhappy'}">
    <svg width="40" height="40" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <g id="line">
        <circle cx="36" cy="36" r="23" fill="var(--head-fill, none)" stroke="currentcolor" stroke-miterlimit="10" stroke-width="3" />
        <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m44.7 43.92c-6.328-1.736-11.41-0.906-17.4 1.902" />
        <path fill="var(--eye-fill, currentcolor)" d="M30,31c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,28,30,29.3448,30,31" />
        <path fill="var(--eye-fill, currentcolor)" d="M48,31c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,29.3448,48,31" />
      </g>
    </svg>
    <span>Unhappy</span>
  </button>

  <!-- NEUTRAL BUTTON -->
  <button type="button" title="Rate Neutral" @click="$store.commit('setRating', 'neutral')" :class="{chosen: rating === 'neutral'}">
    <svg width="40" height="40" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <g id="line">
        <circle cx="36" cy="36" r="23" fill="var(--head-fill, none)" stroke="currentcolor" stroke-miterlimit="10" stroke-width="3" />
        <line x1="27" x2="45" y1="43" y2="43" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
        <path fill="var(--eye-fill, currentcolor)" d="M30,31c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,28,30,29.3448,30,31" />
        <path fill="var(--eye-fill, currentcolor)" d="M48,31c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,29.3448,48,31" />
      </g>
    </svg>
    <span>Neutral</span>
  </button>

  <!-- HAPPY BUTTON -->
  <button type="button" title="Rate Happy" @click="$store.commit('setRating', 'happy')" :class="{chosen: rating === 'happy'}">
    <svg width="40" height="40" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <g id="line">
        <circle cx="36" cy="36" r="23" fill="var(--head-fill, none)" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M45.8149,44.9293 c-2.8995,1.6362-6.2482,2.5699-9.8149,2.5699s-6.9153-0.9336-9.8149-2.5699" />
        <path fill="var(--eye-fill, currentcolor)" d="M30,31c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,28,30,29.3448,30,31" />
        <path fill="var(--eye-fill, currentcolor)" d="M48,31c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,29.3448,48,31" />
      </g>
    </svg>
    <span>Happy</span>
  </button>

  <!-- VERY HAPPY BUTTON -->
  <button type="button" title="Rate Very Happy" @click="$store.commit('setRating', 'very happy')" :class="{chosen: rating === 'very happy'}">
    <svg width="40" height="40" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <g id="line">
        <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M48.1113,44.5467" />
        <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M23.934,44.5467" />
        <circle cx="36" cy="36" r="23" fill="var(--head-fill, none)" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" />
        <path fill="var(--mouth-fill, none)" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M48.5859,42.6735c0,5.6296-4.1784,10.1046-12.5541,10.1046c-8.3738,0-12.6069-4.4888-12.6069-10.1047 C23.4249,42.6734,36.4503,45.7045,48.5859,42.6735z" />
        <path fill="var(--heart-fill, none)" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M26.4992,27.4384c-1.2653-3.3541-6.441-3.5687-6.1168,1.3178c0.0431,0.6485,0.281,1.2724,0.6414,1.8135l5.3179,6.4224l0,0 l5.2212-6.266c0.5796-0.6964,0.9224-1.5779,0.905-2.4853c-0.0863-4.3523-5.0509-4.0351-6.1274-0.8036" />
        <path fill="var(--heart-fill, none)" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M45.8012,27.4384c-1.2547-3.3541-6.3873-3.5687-6.0658,1.3178c0.0428,0.6485,0.2787,1.2724,0.6361,1.8135l5.2737,6.4224l0,0 l5.1777-6.266c0.5747-0.6964,0.9147-1.5779,0.8974-2.4853c-0.0856-4.3523-5.0089-4.0351-6.0763-0.8036" />
      </g>
    </svg>
    <span>Very Happy</span>
  </button>
</div>
</template>

<script>
  import { mapState } from "vuex";
	export default {
		computed: {
			...mapState(['rating']),
		}
	}
</script>

<style lang="scss" scoped>
  h5 {
    margin-top: 1rem;
    color: var(--orange);
    font-weight: normal;
  }
  #rating-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: start;
  }

  button {
    --head-fill: none;
    --mouth-fill: none;
    --heart-fill: none;
    background: transparent;
    border: none;
    color: #fff;
    border-radius: 10px;
    padding: 3px;

    &:hover {
      color: var(--yellow);
      transition: all 250ms;
    }

    &.chosen {
      --head-fill: var(--yellow);
      --mouth-fill: #fff;
      --heart-fill: var(--red);
      color: var(--grey-dark);

      span {
        color: var(--orange);
      }
    }

    span {
      display: block;
      font-size: 0.6rem;
    }
  }
</style>

