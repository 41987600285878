<template>
	<label>
		{{ label }}
		<input :type="type" :value="modelValue" @input="update" :placeholder="placeholder" />
	</label>
</template>

<script setup>
defineProps({
	modelValue: String,
	type: {
		type: String,
		default: 'text'
	},
	placeholder: {
		type: String,
		default: ''
	},
	label:{
		type: String,
		default: ''
	}
})
const emit = defineEmits("update:modelValue");

function update(e) {
	const newVal = e.target.value
	emit("update:modelValue", newVal);
}
</script>


<style lang="scss" scoped>
	label{
		display: block;
		width: 100%;
		margin: 1rem 0;
		font-size: 13px;
		color: var(--orange);
		font-weight: bold;
	}

	input{
		display: block;
		width: 100%;
		font-size: 1rem;
		padding: 10px;
		border: 2px solid transparent;
		margin-top:5px;

		&:focus{
			border-color: var(--orange);
		}
	}
</style>