<template>
	<div class="time-contain">
		<label :for="name" class="label">
			<span class="label">{{label}}<span class="required" v-if="required"> *</span></span>
			<select
				v-model="hour"
				:name="name + '_hour'"
				:id="name + '_hour'"
				:required="required"
				placeholder="00"
				@change="update()"
				>
				<option value="--" disabled>--</option>
				<option v-for="(dumb, n) in 24">{{ n < 10 ? '0'+n : n }}</option>
			</select>&nbsp;:&nbsp;
			<select
			v-model="minute"
			:name="name + '_min'"
			:id="name + '_min'"
			:required="required"
			placeholder="00"
			@change="update()"
			>
			<option value="--" disabled>--</option>
				<option v-for="n in ['00','15','30','45']">{{n}}</option>
			</select> <span class="tip" @click="showClockHelp = !showClockHelp">24-hour clock <button>?</button></span>
			
		</label>
	</div>

	<div class="time-popup" v-if="showClockHelp" @click="showClockHelp = !showClockHelp">
		<div class="wrap">
			<button class="close">&times;</button>
			<p>For times after noon,<br>add 12 hours and remove the "PM"</p>
			<table>
				<tbody>
					<tr><td>1:00PM</td><td>13:00</td></tr>
					<tr><td>2:00PM</td><td>14:00</td></tr>
					<tr><td>3:00PM</td><td>15:00</td></tr>
					<tr><td>4:00PM</td><td>16:00</td></tr>
					<tr><td>5:00PM</td><td>17:00</td></tr>
					<tr><td>6:00PM</td><td>18:00</td></tr>
					<tr><td>7:00PM</td><td>19:00</td></tr>
					<tr><td>8:00PM</td><td>20:00</td></tr>
					<tr><td>9:00PM</td><td>21:00</td></tr>
					<tr><td>10:00PM</td><td>22:00</td></tr>
					<tr><td>11:00PM</td><td>23:00</td></tr>
					<tr><td>MIDNIGHT</td><td>0:00</td></tr>
				</tbody>
			</table>
		</div>
	</div>

</template>

<script>
export default {
	name: 'TimePicker',
	props:{
		name: String,
		label: String,
		value: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean, 
			default: false
		}
	},
	computed: {
		selectedTime: {
			get() {
				return this.hour + ':' + this.minute
			},
			set(newValue) {
				const t = this.value.split(':');
				this.hour = t[0];
				this.minute = t[1];
			}
		}
	},
	methods: {
		update() {
			this.$emit('update:value', this.selectedTime)
		}
	},
	data() {
		return {
			hour: '',
			minute: '',
			showClockHelp: false
		}
	},
	mounted() {
		this.selectedTime = this.value;
	}
}
</script>

<style lang="scss" scoped>
	
	.tip { 
		color: var(--orange); 
		font-size: 0.7em; 
		padding-left: 10px;

		button {
			background: #000;
			color: var(--orange);
			margin-left: 5px;
			display: inline-flex;
			width: 18px;
			height: 18px;
			border: solid 1px var(--orange);
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

	}

	.time-contain{
		margin: 2rem 0;
	}

	.time-popup {
		background: rgba(0,0,0,0.85);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.wrap {
			background: #000;
			max-width: 300px;
			padding: 10px;
			border: solid 1px var(--orange);
			color: var(--orange);
			position: relative;
			.close {
				position: absolute;
				top: 5px;
				right: 5px;
				display: flex;
				width: 24px;
				height: 24px;
				font-size: 24px;
				line-height: 24px;
				background: #000;
				color: var(--orange);
				border: solid 1px var(--orange);
				border-radius: 50%;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}
		table {
			font-weight: normal;
			font-size: 12px;
			color: var(--yellow);
			border-collapse: collapse;
			margin: 10px auto;
			td { 
				border: solid 1px #333; 
				padding: 5px 10px;
				text-align: right;
			}
		}
	}

	label{

		select{
			font-size: 1rem;
			padding: 0.5rem;
			background: #fff;
			border: solid 2px #fff;
			height: 45px;
			transition: all 300ms;


			&:focus{
				border-color: var(--orange);
				outline: solid 1px var(--orange);
				outline-offset: 3px;
			}

			&:disabled{
				opacity: 0.7;
			}
		}
	}

	.instructions{
		margin: 0;
		font-size: 0.8rem;
	}

</style>