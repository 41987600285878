<template>
	<h2>Location</h2>

	<h3>Where is the traffic control work taking place?</h3>
	<Input
		label="Address"
		name="address-intersection-address"
		placeholder=""
		:required="true"
		v-model:value="fields.location.addressIntersection.address" />
	<Input
		label="City"
		name="address-intersection-city"
		placeholder=""
		:required="true"
		v-model:value="fields.location.addressIntersection.city" />
	
	
	<Input
		label="State"
		name="address-intersection-state"
		:required="true"
		placeholder=""
		v-model:value="fields.location.addressIntersection.state" />
	<Input
		label="Zip"
		name="address-intersection-Zip"
		:required="true"
		placeholder=""
		v-model:value="fields.location.addressIntersection.zip" />

	<TextArea 
		label="Additional Details"
		placeholder="Intersecting street names or landmarks"
		v-model:value="fields.location.addressIntersection.additionalDetails" />

	<YesNo
		label="Is Our Crew Meeting You At This Address?"
		v-model:value="fields.location.sameAsLocationOfWorkBool"
		:required="true" />


		<div class="where-should-we-meet-section" v-if="fields.location.sameAsLocationOfWorkBool === 'no'">

			<h3>Where Should We Meet You?</h3>

			<Input
				label="Address"
				name="address-intersection-address"
				placeholder=""
				:required="true"
				v-model:value="fields.location.preJobMeetingLocation.address"/>

			<Input
				label="City"
				name="address-intersection-city"
				placeholder=""
				:required="true"
				v-model:value="fields.location.preJobMeetingLocation.city" />
			
			
			<Input
				label="State"
				name="address-intersection-state"
				:required="true"
				placeholder=""
				v-model:value="fields.location.preJobMeetingLocation.state"/>
			<Input
				label="Zip"
				name="address-intersection-Zip"
				:required="true"
				placeholder=""
				v-model:value="fields.location.preJobMeetingLocation.zip" />

			<TextArea 
				label="Additional Details"
				placeholder="Rest area, service center, parking lot, gas station, etc"
				v-model:value="fields.location.preJobMeetingLocation.additionalDetails" />

		</div>

		<ContinueBtn to="/scope-of-work" :isVisible="showBtn" />
		
</template>

<script>
import { mapState } from 'vuex'
import Input from '@/components/Input.vue'
import TextArea from '@/components/TextArea.vue'
import SameAsBtn from '@/components/SameAsBtn.vue'
import YesNo from '@/components/YesNo.vue'
import ContinueBtn from '@/components/ContinueBtn.vue'

	
export default {
	name: 'Location',
	components:{
		Input,
		TextArea,
		SameAsBtn,
		ContinueBtn,
		YesNo,
	},
	computed: {
		...mapState(['fields']),
		showBtn(){
			let showBtn = true

			if(this.fields.location.addressIntersection.address === '') showBtn = false
			if(this.fields.location.addressIntersection.city === '') showBtn = false
			if(this.fields.location.addressIntersection.state === '') showBtn = false
			if(this.fields.location.addressIntersection.zip === '') showBtn = false

			if(this.fields.location.preJobMeetingLocation.address === '' && this.fields.location.sameAsLocationOfWorkBool === 'no') showBtn = false
			if(this.fields.location.preJobMeetingLocation.city === '' && this.fields.location.sameAsLocationOfWorkBool === 'no') showBtn = false
			if(this.fields.location.preJobMeetingLocation.state === '' && this.fields.location.sameAsLocationOfWorkBool === 'no') showBtn = false
			if(this.fields.location.preJobMeetingLocation.zip === '' && this.fields.location.sameAsLocationOfWorkBool === 'no') showBtn = false

			return showBtn
		}
	},
	mounted(){
		this.$store.commit('addRoute', 2)
	}
}
</script>

<style scoped>
	
	.where-should-we-meet-section{
		overflow: hidden;
		transition: transform 500ms;
		transform-origin: top center;
		transform: scaleY(1);
		height: var(--meetHeight, auto);
	}

	.where-should-we-meet-section.hide{
		height: var(--meetHeight, 0px);
		transform: scaleY(0);
	}
	
</style>